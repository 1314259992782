import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Autocomplete,
  FormControl,
  InputLabel,
  Switch,
  Select,
  MenuItem,
  TableHead,
  TableContainer,
  Collapse,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const PayoutChecker = () => {
  const [userType, setUserType] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showTable, setShowTable] = useState(false); // State to control table visibility
  const classes = useStyles();

  const handleUserTypeChange = async (event) => {
    const selectedUserType = event.target.value;
    setUserType(selectedUserType);

    try {
      const token = localStorage.getItem("jwtToken");
      const response = await fetch(
        `https://api.ipaisa.co.in/myapp/admin/get-users-by-type/${selectedUserType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (data.status === 200) {
        const formattedUsers = data.users.map((user) => ({
          userid: user.userid,
          fullName: `${user.firstName} ${user.lastName}`,
          status: user.status,
          walletBalance: user.walletBalance,
          createdAt: user.createdAt,
          payoutStatus: user.payoutStatus,
        }));
        setUsers(formattedUsers);
        setFilteredUsers(formattedUsers); // Initialize filtered users
        setShowTable(true); // Show table after user type selection
      } else {
        console.error("Failed to fetch users:", data.message);
        setUsers([]);
        setFilteredUsers([]);
        setShowTable(false); // Hide table on failure
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
      setFilteredUsers([]);
      setShowTable(false); // Hide table on error
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter users based on search query (case-insensitive)
    const filtered = users.filter(
      (user) =>
        user.fullName.toLowerCase().includes(query) ||
        user.userid.toLowerCase().includes(query) ||
        user.status.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  const handleClear = () => {
    setSearchQuery("");
    setFilteredUsers(users); // Reset the filtered list
  };

  const handleToggleStatus = async (userId) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await fetch(
        `https://api.ipaisa.co.in/myapp/admin/change-payout-status/${userId}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();

      if (data.status === "status change successfully") {
        toast.success("Payout status updated successfully");

        setUsers((prevUsers) =>
          prevUsers.map((user) => {
            if (user.userid === userId) {
              return {
                ...user,
                payoutStatus:
                  user.payoutStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                status: user.payoutStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE",
              };
            }
            return user;
          })
        );

        setFilteredUsers((prevFilteredUsers) =>
          prevFilteredUsers.map((user) =>
            user.userid === userId
              ? {
                  ...user,
                  payoutStatus:
                    user.payoutStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                  status:
                    user.payoutStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE", // Sync status field
                }
              : user
          )
        );
      } else {
        toast.error("Failed to change payout status");
        console.error("Failed to change payout status:", data.message);
      }
    } catch (error) {
      toast.error("Error changing payout status");
      console.error("Error changing payout status:", error);
    }
  };

  return (
    <Container style={{ padding: "1rem", marginTop: "calc(4% + 58px)" }}>
      <ToastContainer />
      {/* Header Section */}
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Balance
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Payout Checker
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "1%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Payout Checker
        </Typography>
      </Grid>

      {/* Form Section */}
      <Paper
        style={{
          boxShadow: "none",
          padding: "2rem",
          marginTop: "2%",
          borderRadius: "10px",
          boxShadow: "none",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>User Type</InputLabel>
              <Select
                label="User Type"
                value={userType}
                onChange={handleUserTypeChange}
              >
                <MenuItem value="AREADISTRIBUTOR">Area Distributor</MenuItem>
                <MenuItem value="RETAILER">Retailer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearch}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <Button
              onClick={handleClear}
              sx={{
                backgroundColor: "gray",
                color: "white",
                textTransform: "none",
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Table Section with Animation */}
      <Grid style={{ marginTop: "3%" }}>
        <Collapse in={showTable}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "12px 12px 0px 0px", boxShadow: "none" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#027BFE", color: "white" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>User ID</TableCell>
                  <TableCell style={{ color: "white" }}>User Name</TableCell>
                  <TableCell style={{ color: "white" }}>Status</TableCell>
                  <TableCell style={{ color: "white" }}>
                    Wallet Balance
                  </TableCell>
                  <TableCell style={{ color: "white" }}>Created At</TableCell>
                  <TableCell style={{ color: "white" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user, index) => (
                  <TableRow
                    key={user.userid}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell>{user.userid}</TableCell>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>{user.status}</TableCell>{" "}
                    {/* Status dynamically updates */}
                    <TableCell>
                      {Number(user.walletBalance).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {new Date(user.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={user.payoutStatus === "ACTIVE"}
                        onChange={() => handleToggleStatus(user.userid)}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green",
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            { backgroundColor: "green" },
                          "& .MuiSwitch-switchBase:not(.Mui-checked)": {
                            color: "red",
                          },
                          "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track":
                            { backgroundColor: "red" },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Grid>
    </Container>
  );
};

export default PayoutChecker;
