import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const DeepLinkPage = () => {
  const navigate = useNavigate();
  const [walletBalance, setWalletBalance] = useState(0);
  const [decryptedData, setDecryptedData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [txnid, setTxnid] = useState("");

  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();

    const path = window.location.pathname;
    const encryptedData = path.replace("/deeplinkpage/", "");

    if (encryptedData) {
      decryptPayload(encryptedData);
    }
  }, []);

  const decryptPayload = async (encryptedData) => {
    try {
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/pg/decryptPayload",
        { data: encryptedData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "Success") {
        const decrypted = response.data.data;
        setDecryptedData(decrypted);
        console.log(decrypted);
        formik.setFieldValue("amount", decrypted.amount);
        setTxnid(decrypted.txnid);
      } else {
        console.error("Failed to decrypt data:", response.data.message);
      }
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: decryptedData?.amount || "",
    },
  });

  const initiatePayment = async (decryptedData) => {
    try {
      const paymentData = {
        ...decryptedData,
        productinfo: "Add Money",
        firstname: "Edsom",
      };

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/ezpg/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseBody = response.data.body;
      const parsedBody = JSON.parse(responseBody);

      if (parsedBody.status === 1) {
        const accessKey = parsedBody.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
        const newWindow = window.open(paymentUrl, "_blank");

        if (newWindow) {
          newWindow.focus();

          const checkWindowLocation = setInterval(() => {
            try {
              if (newWindow.location.href === "https://test.ipaisa.co.in/") {
                clearInterval(checkWindowLocation);
                newWindow.close();
                checkPaymentStatus(paymentData.txnid);
              }
            } catch (e) {
              console.error("Cross-origin error:", e);
            }
          }, 1000);
        }
      } else {
        toast.error("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const checkPaymentStatus = async (txnid) => {
    try {
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/ezpg/transaction-api-v2",
        { txnid }
      );
      const result = response.data;
      if (result.status === "success") {
        console.log("Payment successful:", result.message);

        // Navigate to the desired URL
        window.location.href = decryptedData.surl;
      } else {
        window.location.href = decryptedData.furl;
        console.error("Payment failed:", result.message);
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#F2F5FA" }}>
      <Container
        maxWidth="lg"
        style={{
          width: "90%",
          marginTop: "7%",
          padding: "2%",
          height: "80%",
          overflow: "hidden",
        }}
      >
        <Paper
          elevation={3}
          style={{ padding: "2%", borderRadius: "12px", height: "70%" }}
        >
          <Grid container alignItems="center" justifyContent="space-evenly">
            <Grid item xs={12} sm={6} md={6} sx={{ marginTop: "8%" }}>
              <form style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h7">Amount</Typography>
                    <TextField
                      name="amount"
                      type="number"
                      value={formik.values.amount}
                      fullWidth
                      required
                      disabled
                      InputLabelProps={{
                        style: { height: "2.5em" },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      style={{
                        textAlign: "center",
                        color: "#D9D9D9",
                        width: "100%",
                        height: "60px",
                        borderRadius: "12px",
                        marginTop: "20px",
                      }}
                      onClick={() => initiatePayment(decryptedData)}
                      variant="contained"
                      color="primary"
                      disabled={isProcessing}
                    >
                      {"Pay Now"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default DeepLinkPage;
