import axios from "axios";
import { PAYOUT_SUCCESS, PAYOUT_FAILURE } from "../actionTypes";
import { toast } from "react-toastify";
import { getToastMessage } from "./statusMessages"; // Adjust the path as needed

export const initiatePayout = (
  payload,
  setShowSuccessPayout,
  setInsufficientB,
  setShowFailedPayout
) => {
  return async (dispatch) => {
    const token = localStorage.getItem("jwtToken");

    // Get activeProvider from localStorage
    const activeProvider = localStorage.getItem("activeProvider");
    console.log(activeProvider, "activeProvider");
    if (!activeProvider) {
      toast.error("No active payout provider found.");
      return; // Exit if no active provider is found
    }

    try {
      let response;

      // Check the active provider and make the corresponding API call
      if (activeProvider === "INSTANTPAY") {
        response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/instantpay/payout",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else if (activeProvider === "RAZORPAY") {
        const razorpayPayload = { ...payload };

        response = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/razorpayout",
          razorpayPayload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      const status = response.status;

      // Handle successful payout
      if (status === 200) {
        dispatch({ type: PAYOUT_SUCCESS, payload: response.data });
        toast.success(getToastMessage(status));
        setShowSuccessPayout(true); // Show success popup
      } else if (status === 402) {
        toast.success(getToastMessage(status));
        dispatch({ type: PAYOUT_FAILURE, payload: getToastMessage(status) });
        setInsufficientB(true);
      } else {
        toast.error(getToastMessage(status));
        dispatch({ type: PAYOUT_FAILURE, payload: getToastMessage(status) });
        setShowFailedPayout(true);
      }
    } catch (error) {
      console.error("Payout failed:", error);
      const status = error.response ? error.response.status : null;
      toast.error(getToastMessage(status, "Payout failed. Please try again."));
      setShowFailedPayout(true);
      dispatch({ type: PAYOUT_FAILURE, payload: error.message });
    }
  };
};
