import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Box,
  InputBase,
} from "@mui/material";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import axios from "axios";
import { toast } from "react-toastify";
import CommissionInvoiceReport from "./CommissionInvoiceReport";
import PendingRefundReportsInvoice from "./PendingRefundReportsInvoice";
import html2pdf from "html2pdf.js";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  tableRow: {
    "& > *": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));
const PartnerCommissionReports = () => {
  const classes = useStyles();
  const [totalCommission, setTotalCommission] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [statusCodeValue, setStatusCodeValue] = useState(null); // State to manage status code
  const [filters, setFilters] = useState({
    transactionId: "",
    payinUserId: "",
    receiverUserId: "",
    commissionAmount: "",
    commissionStatus: "",
  });
  // Function to fetch data from API

  const handleDownloadInvoice = async (data) => {
    const receiptElement = document.getElementById(
      `receipt-${data.payinUserId}`
    );

    if (!receiptElement) {
      console.error(
        "Receipt element not found:",
        `receipt-${data.payinUserId}`
      );
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `Payout Receipt-${data.payinUserId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", orientation: "landscape", format: "a4" },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  const fetchCompleteData = async (
    page,
    fromDate,
    toDate,
    searchQuery,
    rowsPerPage
  ) => {
    setLoading(true);

    const token = localStorage.getItem("jwtToken"); // Retrieve the token from localStorage

    // Ensure searchQuery is a string and trim any spaces
    const safeSearchQuery = searchQuery ? searchQuery.trim() : ""; // Fallback to empty string if undefined/null

    // Build the URL parameters conditionally
    let url = "https://api.ipaisa.co.in/myapp/auth/userCommissionReport?";

    // Add search query if available
    if (safeSearchQuery) {
      url += `searchBy=${safeSearchQuery}&`;
    }

    // Add fromDate if available
    if (fromDate) {
      url += `fromDate=${fromDate}&`;
    }

    // Add toDate if available
    if (toDate) {
      url += `toDate=${toDate}&`;
    }

    // Add pagination parameters (page and rowsPerPage)
    url += `page=${page}&rowsPerPage=${rowsPerPage}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Check for no content (204 or message "No Transactions Found")
      if (
        response.data.statusCodeValue === 204 ||
        response.data.body.message === "NO_CONTENT"
      ) {
        console.log("No content available or No Transactions Found");
        setRows([]); // Clear the data state if no content
        setStatusCodeValue(response.data.statusCodeValue);
      } else {
        setRows(response.data.body.data); // Set the data to the state if content is available
        setTotalRecords(response.data.body.totalRecords || 0); // Assuming totalCount is provided in the response
        setTotalCommission(response.data.body.totalCommission || 0);
      }
    } catch (error) {
      console.error("Error fetching reverse money transactions:", error);
      setRows([]); // Optional: Clear data in case of error
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts or when page or rowsPerPage changes
    fetchCompleteData(currentPage, fromDate, toDate, searchQuery, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const handleSearchInput = (event) => {
    const updatedSearchQuery = event.target.value; // Get the updated search query value
    setSearchQuery(updatedSearchQuery); // Update search query state

    // Check if the search query length becomes 0 (i.e., the input is cleared)
    if (updatedSearchQuery.length === 0) {
      // Fetch data with default parameters when search query is cleared
      fetchCompleteData(0, "", "", "", rowsPerPage);
    }
  };

  const handleSearch = () => {
    setCurrentPage(0); // Reset to the first page on new search
    fetchCompleteData(0, fromDate, toDate, searchQuery, rowsPerPage); // Call API with search query and date filters
  };

  const handleClear = () => {
    setFromDate("");
    setToDate("");
    setSearchQuery("");
    setCurrentPage(0); // Reset to first page
    fetchCompleteData(0, "", "", "", rowsPerPage); // Fetch data with default params when cleared
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage); // Update the page state
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(0); // Reset to first page when rows per page changes
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error}</Typography>;

  return (
    <Container sx={{ padding: "2rem", marginTop: "7%" }}>
      {/* Header and Summary Sections */}
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Reports
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Commission Report
        </Typography>
      </Grid>

      <Grid sx={{ marginTop: "1%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Commission Report
        </Typography>
      </Grid>

      <Grid style={{ marginTop: "3%" }}>
        <Grid container spacing={1}>
          {/* Card Section */}
          <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
            <Paper
              style={{
                boxShadow: "none",
                padding: "2rem",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Card
                style={{
                  width: "100%",
                  height: "260px",
                  borderRadius: "12px",
                  alignContent: "center",
                  textAlign: "center",
                  background: "linear-gradient(135deg, #413DFF, #547AFF)",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Typography
                    color="textSecondary"
                    sx={{ color: "#FFFFFF", fontSize: "22px" }}
                    gutterBottom
                  >
                    Total Commission
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontSize: "30px" }}
                    component="h2"
                  >
                    ₹ {totalCommission.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>

          {/* Form Section */}
          <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
            <Paper
              sx={{
                padding: "2rem",
                borderRadius: "8px",
                overflow: "hidden", // Prevent content overflow
                boxShadow: "none",
              }}
            >
              <Grid container spacing={2}>
                {/* Date Fields */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="From Date"
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="To Date"
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>

                {/* Search and Buttons */}
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: "1%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  {/* Search Field */}
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      maxWidth: { xs: "100%", sm: "50%" },
                      borderRadius: "10px",
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      padding: "0.5rem",
                    }}
                  >
                    <InputBase
                      fullWidth
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchInput} // Updates search query
                    />
                    <IconButton color="primary" onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </Box>

                  {/* Action Buttons */}
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "1rem",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      onClick={handleSearch}
                      sx={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        height: "56px",
                        textTransform: "none",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "120px" },
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={handleClear}
                      sx={{
                        backgroundColor: "gray",
                        color: "white",
                        height: "56px",
                        textTransform: "none",
                        borderRadius: "10px",
                        width: { xs: "100%", sm: "120px" },
                      }}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Table Section */}
      <Grid sx={{ marginTop: "3%" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          History
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            marginTop: "2%",
            maxWidth: "100%",
            overflowX: "auto",
            borderRadius: "12px 12px 0px 0px",
            boxShadow: "none",
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "#027BFE" }}>
              <TableRow>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Sr. No.
                </TableCell>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Transaction ID
                </TableCell>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Payin User ID
                </TableCell>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Commission Amount
                </TableCell>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Transaction Amount
                </TableCell>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Transaction Date
                </TableCell>
                <TableCell sx={{ color: "white", textAlign: "start" }}>
                  Status
                </TableCell>
                {/* <TableCell sx={{ color: 'white', textAlign: 'start' }}>Invoice</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(rows) && rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell align="start">
                      {index + 1 + currentPage * rowsPerPage}
                    </TableCell>
                    <TableCell align="start">{row.transactionId}</TableCell>
                    <TableCell align="start">{row.payinUserId}</TableCell>
                    <TableCell align="start">
                      {Number(row.commissonAmount).toFixed(2)}
                    </TableCell>
                    <TableCell align="start">
                      {Number(row.txnAmount).toFixed(2)}
                    </TableCell>
                    <TableCell align="start">
                      {new Date(row.createATime).toLocaleDateString("en-GB")}{" "}
                      {new Date(row.createATime).toLocaleTimeString("en-US")}
                    </TableCell>
                    {/* <TableCell align="center">{row.statu}</TableCell> */}
                    <TableCell
                      align="start"
                      style={{
                        color:
                          row.statu === "Success"
                            ? "green"
                            : row.statu === "failure"
                              ? "red"
                              : row.statu === "userCancelled"
                                ? "red"
                                : "inherit", // Default text color
                        fontWeight: "bold",
                      }}
                    >
                      {row.statu === "Success"
                        ? "Successful"
                        : row.statu === "failure"
                          ? "Failed"
                          : row.statu === "userCancelled"
                            ? "User Cancelled"
                            : row.statu === "preinitiated"
                              ? "Preinitiated"
                              : row.statu || "N/A"}{" "}
                      {/* Default value if no match */}
                    </TableCell>
                    {/* <TableCell align="center">
                      <DownloadIcon
                        onClick={() => handleDownloadInvoice(row)}
                        style={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                    <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                      <Grid id={`receipt-${row.payinUserId}`}>
                        <CommissionInvoiceReport data={row} />
                      </Grid>
                    </Grid> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Pagination Section */}
      <TablePagination
        component="div"
        count={totalRecords}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        sx={{ marginTop: 2 }}
        nextIconButtonProps={{
          disabled:
            (currentPage + 1) * rowsPerPage >= totalRecords ||
            statusCodeValue === 204,
        }}
        backIconButtonProps={{
          disabled: currentPage === 0,
        }}
      />
    </Container>
  );
};

export default PartnerCommissionReports;
