import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CardMedia,
  Paper,
  Container,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import PieChartComponent from "./PieChartComponent";
// import { useDevice } from "react-detect-device";
import apibalance from "../Assets/Dashboard/apiBalance.svg";
import walletbalance from "../Assets/Dashboard/walletBalance.svg";
import iwalletbalance from "../Assets/Dashboard/iWallet.svg";
import iatmbalance from "../Assets/Dashboard/iAtm.svg";
import morqueimg from "../Assets/Dashboard/morqueimg.jpeg";
import "@fontsource/roboto";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import {
  AddCardIcon,
  AUBankIcon,
  AxisBankIcon,
  IDFCBankIcon,
  RBLBankIcon1,
  RBLBankIcon2,
  UnionBankIcon,
  YesBankIcon,
} from "../../res/icons";
import RetailerPieCharts from "./RetailerPieCharts";
import {
  fetchPartnerData,
  fetchWalletBalance,
} from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  PayInText: {
    color: "green",
  },
}));

const Dashboard = () => {
  const userType = localStorage.getItem("uType");
  const classes = useStyles();
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [transactions, setTransactions] = useState({
    PayOut: [],
    PayIn: [],
    FundTransfer: [],
  });
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog visibility
  const [formData, setFormData] = useState({
    bankName: "",
    companyName: "",
    accountNo: "",
    ifsc: "",
    branch: "",
    bankImage: null, // For uploading the bank image
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    setFormData((prev) => ({ ...prev, bankImage: e.target.files[0] }));
  };

  // Handle dialog open
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  // Handle dialog close
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // Handle form submission
  const handleSubmit = () => {
    const newCard = {
      bankName: formData.bankName,
      company: formData.companyName,
      accountNo: formData.accountNo,
      ifsc: formData.ifsc,
      branch: formData.branch,
      icon: UnionBankIcon, // Replace with default or uploaded icon if necessary
      color: "#" + Math.floor(Math.random() * 16777215).toString(16), // Random color generation
    };
    // Handle form submission logic here
    console.log(cardsData);
    setCardsData((prevCards) => [...prevCards, newCard]);
    console.log(cardsData);
    console.log("Form Data:", formData);
    setOpenDialog(false);
  };
  const handleViewDetails = (index) => {
    setActiveCardIndex(index);
  };

  const [cardsData, setCardsData] = useState([
    // {
    //     bankName: "YES BANK",
    //     company: "EDSOM FINTECH PVT.LTD.",
    //     accountNo: "120664700000021",
    //     ifsc: "YESB0000008",
    //     branch: "Prabhat Road, Pune",
    //     icon: YesBankIcon, // Use the desired icon
    //     color: "#0002D8",
    // },
    // {
    //     bankName: "UNION BANK",
    //     company: "EDSOM FINTECH PVT.LTD.",
    //     accountNo: "583801010050602",
    //     ifsc: "UBIN0558389",
    //     branch: "Baner, Pune",
    //     icon: UnionBankIcon, // Use the desired icon
    //     color: "#D81F40"
    // },
    // {
    //     bankName: "AU SMALL Finance Bank",
    //     company: "EDSOM FINTECH PVT.LTD.",
    //     accountNo: "2402235358748585",
    //     ifsc: "AUBL0002353",
    //     branch: "Prabhat Road, Pune",
    //     icon: AUBankIcon, // Use the desired icon
    //     color: "#660F59"
    // },
    // {
    //     bankName: "AXIS Bank",
    //     company: "EDSOM FINTECH PVT.LTD.",
    //     accountNo: " 919020076135843",
    //     ifsc: "UTIB0002754",
    //     branch: "Balewadi, Pune",
    //     icon: AxisBankIcon, // Use the desired icon
    //     color: "#721324"
    // },
    {
      bankName: "RBL Bank",
      company: "EDSOM FINTECH PVT.LTD.",
      accountNo: "409021559927",
      ifsc: "RATN0000512",
      branch: "Pashan, Pune",
      icon: RBLBankIcon2, // Path to the icon image
      color: "#21317D", // RBL Bank red color
    },
    // {
    //     bankName: "IDFC Bank",
    //     company: "EDSOM FINTECH PVT.LTD.",
    //     accountNo: "10066469212",
    //     ifsc: "IDFB0041433",
    //     branch: "Balewadi, Pune",
    //     icon: IDFCBankIcon, // Use the desired icon
    //     color: "#991F35"
    // },
  ]);
  const dispatch = useDispatch();
  const walletBalance = useSelector((state) => state.dashboard.walletBalance);
  const partnerCount = useSelector((state) => state.dashboard.partnerCount);

  useEffect(() => {
    dispatch(fetchWalletBalance());
    dispatch(fetchPartnerData());
  }, [dispatch]);

  // useEffect(() => {
  //     fetchBalance();
  //     fetchPartnerData();
  // }, []);

  const username = localStorage.getItem("username");
  const date = new Date();
  const hour = date.getHours();
  let greeting;

  if (hour >= 16) {
    greeting = "Good Evening";
  } else if (hour >= 12) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Morning";
  }

  const cardData = [
    {
      label: "Channel Partners",
      count: partnerCount.channelPartnerCount,
      color: "#092E1F",
    },
    {
      label: "Super Distributors",
      count: partnerCount.superDistributerCount,
      color: "#4B0DE1",
    },
    {
      label: "Master Distributors",
      count: partnerCount.masterDistributerCount,
      color: "#AA336A",
    },
    {
      label: "Area Distributors",
      count: partnerCount.areaDistributerCount,
      color: "#FF7B31",
    },
    { label: "Retailers", count: partnerCount.retailerCount, color: "#00DDE5" },
    { label: "Users", count: partnerCount.userCount, color: "#027BFE" },
  ];

  const filterCards = (userType) => {
    switch (userType) {
      case "ADMIN":
        return cardData; // Show all 6 cards
      case "CHANNELPARTNER":
        return cardData.slice(1); // Show cards from index 1 to the end
      case "SUPERDISTRIBUTOR":
        return cardData.slice(2); // Show cards from index 2 to the end
      case "MASTERDISTRIBUTOR":
        return cardData.slice(3); // Show cards from index 3 to the end
      case "AREADISTRIBUTOR":
        return cardData.slice(4); // Show cards from index 4 to the end
      default:
        return [];
    }
  };

  // const filteredCardData = filterCards(userType);

  const filteredCardData = filterCards(userType);
  console.log(filteredCardData);

  useEffect(() => {
    lastTransactions();
  }, []);

  const lastTransactions = async () => {
    const token = localStorage.getItem("jwtToken");
    const response = await axios.get(
      "https://api.ipaisa.co.in/myapp/auth/getLatestTxn",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(
      response.statusCode,
      "Response status code: " + response.status
    );
    if (response.status === 200) {
      // const data = await response.json();
      console.log(response.body);

      // Separate PayIn transactions based on `mode`
      const payIn = response.data.body.filter(
        (txn) =>
          txn.cardType === "UPI" ||
          txn.cardType === "Credit Card" ||
          txn.cardType === "Debit Card"
      );
      // Separate PayOut transactions based on `poolMode` being "DR"
      const payOut = response.data.body.filter((txn) => txn.poolMode === "DR");

      console.log("Response Data", response.data.body);
      console.log("Payin data ", payIn);
      console.log("PayOut Data", payOut);
      setTransactions({
        PayOut: payOut,
        PayIn: payIn,
      });
    }
  };

  const [currentView, setCurrentView] = useState("PayOut");

  // Sample data for each transaction type
  const transactionData = {
    PayOut: [
      {
        txnId: "TXN1234",
        amount: "100.00",
        openingBalance: "130.757",
        closingBalance: "110.089",
        accountNo: "250028989",
        payeeName: "Kamran Khan",
      },
      // Add more PayOut transactions here
    ],
    PayIn: [
      {
        txnId: "TXN5678",
        amount: "200.00",
        openingBalance: "110.089",
        closingBalance: "310.089",
        accountNo: "3800754867",
        payeeName: "Mheboob Pathan",
      },
      // Add more PayIn transactions here
    ],
    FundTransfer: [
      {
        txnId: "TXN9101",
        amount: "500.00",
        openingBalance: "310.089",
        closingBalance: "810.089",
        accountNo: "4877666766",
        payeeName: "Yash Suryavanshi",
      },
      // Add more Fund Transfer transactions here
    ],
  };

  const renderTableRows = () => {
    return transactions[currentView].map((txn, index) => {
      // Conditional rendering for different views
      if (currentView === "PayOut") {
        return (
          <TableRow key={index}>
            <TableCell align="start">{txn.externalRef}</TableCell>
            <TableCell align="start">{txn.payeeAccount}</TableCell>
            <TableCell align="start">{txn.payeeName}</TableCell>
            <TableCell align="start">
              {Number(txn.walletOpeningBalance).toFixed(2)}
            </TableCell>
            <TableCell align="start">{"₹" + txn.txnValue}</TableCell>
            <TableCell align="start">
              {Number(txn.walletClosingBalance).toFixed(2)}
            </TableCell>
            <TableCell align="start">{txn.status}</TableCell>
          </TableRow>
        );
      } else if (currentView === "PayIn") {
        return (
          <TableRow key={index}>
            <TableCell align="start">{txn.txnid}</TableCell>
            <TableCell align="start">{txn.cardType}</TableCell>
            {/* Conditional rendering for "Payment By" based on cardType */}
            {txn.cardType === "UPI" ? (
              <TableCell align="start">{txn.upiVa}</TableCell> // Payment By UPI
            ) : txn.cardType === "Credit Card" ||
              txn.cardType === "Debit Card" ? (
              <TableCell align="start">{txn.cardnum}</TableCell> // Payment By Card Number
            ) : (
              <TableCell align="start">N/A</TableCell> // Handle other card types if necessary
            )}
            <TableCell align="start">{"₹" + txn.amount}</TableCell>
            <TableCell align="start">{txn.status}</TableCell>
          </TableRow>
        );
      } else if (currentView === "FundTransfer") {
        return (
          <TableRow key={index}>
            <TableCell align="start">{txn.txnReferenceId}</TableCell>
            <TableCell align="start">{txn.transferAccount}</TableCell>
            <TableCell align="start">{txn.beneficiaryName}</TableCell>
            <TableCell align="start">
              {Number(txn.walletOpeningBalance).toFixed(2)}
            </TableCell>
            <TableCell align="start">{"₹" + txn.txnValue}</TableCell>
            <TableCell align="start">
              {Number(txn.walletClosingBalance).toFixed(2)}
            </TableCell>
            <TableCell align="start">{txn.status}</TableCell>
          </TableRow>
        );
      } else {
        return null; // Handle other cases or return null if not needed
      }
    });
  };

  return (
    <Grid>
      <Grid style={{ margin: "2%" }}>
        <Grid container spacing={2} style={{ marginTop: "10%" }}>
          <marquee
            style={{
              backgroundColor: "#031273",
              padding: "10px",
              color: "#000",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#ffffff",
            }}
          >
            Hello {username}.Uncover the Latest Insights — {"  "}
            <a
              href={morqueimg}
              rel="noopener noreferrer"
              style={{
                color: "Red",
                textDecoration: "none",
                animation: "blink 1s step-end infinite",
              }}
            >
              Click Here
            </a>
          </marquee>
          <style>
            {`
                    @keyframes blink {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                    `}
          </style>
          <style>
            {`
                        @keyframes blink {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                        }
                    `}
          </style>
          <style>
            {`
                                @keyframes blink {
                                0% { opacity: 1; }
                                50% { opacity: 0; }
                                100% { opacity: 1; }
                                }
                                `}
          </style>
          <Grid item xs={12} style={{ paddingLeft: "3%" }}>
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {greeting}, {username}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: "1%" }}
            >
              Welcome back! Ready to make today amazing, Let's get started!
            </Typography>
          </Grid>

          {userType === "ADMIN" ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    padding: "32px 30px",
                    borderRadius: "20px",
                    width: "65%",
                    marginLeft: "8%",
                    backgroundColor: "#00178D",
                    color: "white",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid item style={{ width: "60px", height: "60px" }}>
                      <CardMedia
                        style={{
                          borderRadius: "6px",
                          width: "100%",
                          height: "100%",
                          fontSize: "24px",
                        }}
                        component="img"
                        alt="API Balance"
                        src={apibalance}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        color="white"
                        style={{ fontFamily: "Roboto" }}
                        gutterBottom
                      >
                        API Balance
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ textAlign: "start", fontFamily: "Roboto" }}
                      >
                        ₹ 1,50,000.00
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ) : userType === "RETAILER" ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    padding: "32px 30px",
                    borderRadius: "20px",
                    width: "65%",
                    marginLeft: "8%",
                    backgroundColor: "#FF8C00",
                    color: "white",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid item style={{ width: "60px", height: "60px" }}>
                      <CardMedia
                        style={{
                          borderRadius: "6px",
                          width: "100%",
                          height: "100%",
                          fontSize: "24px",
                        }}
                        component="img"
                        alt="iWallet"
                        src={iwalletbalance}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        color="white"
                        style={{ fontFamily: "Roboto" }}
                        gutterBottom
                      >
                        iIT
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ textAlign: "start", fontFamily: "Roboto" }}
                      >
                        ₹ 0.00
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    padding: "32px 30px",
                    borderRadius: "20px",
                    width: "65%",
                    marginLeft: "8%",
                    backgroundColor: "#77279D",
                    color: "white",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid item style={{ width: "60px", height: "60px" }}>
                      <CardMedia
                        style={{
                          borderRadius: "6px",
                          width: "100%",
                          height: "100%",
                          fontSize: "24px",
                        }}
                        component="img"
                        alt="iATM"
                        src={iatmbalance}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        color="white"
                        style={{ fontFamily: "Roboto" }}
                        gutterBottom
                      >
                        iATM
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ textAlign: "start", fontFamily: "Roboto" }}
                      >
                        ₹0.00
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              style={{
                padding: "32px 30px",
                borderRadius: "20px",
                width: "65%",
                marginLeft: "8%",
                backgroundColor: "#008D39",
                color: "white",
              }}
            >
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="flex-start"
              >
                <Grid item style={{ width: "60px", height: "60px" }}>
                  <CardMedia
                    style={{
                      borderRadius: "6px",
                      width: "100%",
                      height: "100%",
                      fontSize: "24px",
                    }}
                    component="img"
                    alt="Wallet Balance"
                    src={walletbalance}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    color="white"
                    style={{ fontFamily: "Roboto" }}
                    gutterBottom
                  >
                    Wallet Balance
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ textAlign: "start", fontFamily: "Roboto" }}
                  >
                    ₹ {walletBalance.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          direction="column"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        >
          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                paddingBottom: "2%",
              }}
            >
              Last 5 Transactions{" "}
              <span style={{ color: "#027BFE" }}>({currentView})</span>
            </Typography>
          </Grid>
          <Grid item style={{ paddingBottom: "2%" }}>
            <Box
              style={{
                display: "flex",
                marginY: "1rem",
                gap: "3%",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  backgroundColor: "transparent", // Keep background unchanged
                  color: currentView === "PayOut" ? "#027BFE" : "inherit", // Change color if active
                  borderColor: "transparent", // No border on the button itself
                  borderBottom:
                    currentView === "PayOut"
                      ? "2px solid #027BFE"
                      : "2px solid transparent", // Show underline if active
                  borderRadius: 0, // Remove rounded corners on the underline
                  "&:hover": {
                    backgroundColor: "transparent", // Keep hover background transparent
                    borderBottom: "2px solid #027BFE", // Keep underline on hover
                  },
                  textTransform: "none",
                }}
                onClick={() => setCurrentView("PayOut")}
              >
                PayOut
              </Button>
              <Button
                sx={{
                  backgroundColor: "transparent", // Keep background unchanged
                  color: currentView === "PayIn" ? "#027BFE" : "inherit", // Change color if active
                  borderColor: "transparent", // No border on the button itself
                  borderBottom:
                    currentView === "PayIn"
                      ? "2px solid #027BFE"
                      : "2px solid transparent", // Show underline if active
                  borderRadius: 0, // Remove rounded corners on the underline
                  "&:hover": {
                    backgroundColor: "transparent", // Keep hover background transparent
                    borderBottom: "2px solid #027BFE", // Keep underline on hover
                  },
                  textTransform: "none",
                }}
                onClick={() => setCurrentView("PayIn")}
              >
                PayIn
              </Button>
              <Button
                sx={{
                  backgroundColor: "transparent", // Keep background unchanged
                  color: currentView === "FundTransfer" ? "#027BFE" : "inherit", // Change color if active
                  borderColor: "transparent", // No border on the button itself
                  borderBottom:
                    currentView === "FundTransfer"
                      ? "2px solid #027BFE"
                      : "2px solid transparent", // Show underline if active
                  borderRadius: 0, // Remove rounded corners on the underline
                  "&:hover": {
                    backgroundColor: "transparent", // Keep hover background transparent
                    borderBottom: "2px solid #027BFE", // Keep underline on hover
                  },
                  textTransform: "none",
                }}
                onClick={() => setCurrentView("FundTransfer")}
              >
                Fund Transfer
              </Button>
            </Box>
          </Grid>

          <Grid item>
            <TableContainer
              component={Paper}
              style={{
                maxWidth: "100%",
                overflowX: "auto",
                borderRadius: "14px 14px 0px 0px",
              }}
            >
              <Table>
                <TableHead
                  style={{ backgroundColor: "#B59410", color: "white" }}
                >
                  <TableRow>
                    {currentView === "PayOut" && (
                      <>
                        <TableCell style={{ color: "white" }} align="start">
                          Txn ID
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Account No.
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Payee Name
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Opening Balance
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Amount
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Closing Balance
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Status
                        </TableCell>
                      </>
                    )}
                    {currentView === "PayIn" && (
                      <>
                        <TableCell style={{ color: "white" }} align="start">
                          Txn ID
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Payment Type
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Payment By
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Amount
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Status
                        </TableCell>
                      </>
                    )}
                    {currentView === "FundTransfer" && (
                      <>
                        <TableCell style={{ color: "white" }} align="start">
                          Txn ID
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Transfer Account
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Beneficiary Name
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Opening Balance
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Amount
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Closing Balance
                        </TableCell>
                        <TableCell style={{ color: "white" }} align="start">
                          Status
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions[currentView] &&
                  transactions[currentView].length > 0 ? (
                    renderTableRows() // Render rows conditionally based on currentView
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No transactions found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        {userType !== "RETAILER" && (
          <Container
            maxWidth="false"
            style={{
              marginTop: "2%",
              width: "100%",
              justifyContent: "flex-start",
              marginRight: "25%",
            }}
          >
            <Grid container spacing={2}>
              <Paper
                style={{
                  boxShadow: "none",
                  padding: "20px",
                  textAlign: "center",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 20,
                  height: "350px",
                  width: "calc(100% - 4%)",
                  marginBottom: "16px",
                  display: "flex",
                }}
              >
                <Grid container direction="column">
                  <Grid item spacing={2} style={{ marginTop: "4%" }}>
                    <Typography
                      variant="h2"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginRight: "75%",
                      }}
                    >
                      Records
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={10}
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      flexDirection: "row",
                      width: "100%",
                      height: "100%",
                      marginTop: "4%",
                    }}
                  >
                    {filteredCardData.map((card, index) => (
                      <Grid
                        item
                        md={4}
                        lg={2}
                        key={index}
                        style={{
                          textAlign: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          style={{
                            width: "140%",
                            height: "75%",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: card.color,
                            color: "white",
                            borderRadius: "15px",
                            marginTop: "6%",
                            marginLeft: "-50%",
                          }}
                        >
                          <Grid item style={{ marginBottom: "5%" }}>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            >
                              {card.count}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              alignItems: "center",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="body1">
                              {card.label}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Container>
        )}

        <Grid
          container
          spacing={1}
          style={{ marginTop: "2%", width: "100%", marginRight: "10%" }}
        >
          <Grid item xs={12}>
            {userType === "RETAILER" ? (
              <RetailerPieCharts />
            ) : (
              <PieChartComponent />
            )}
          </Grid>
        </Grid>

        <Container
          maxWidth="false"
          style={{
            marginTop: "2%",
            width: "100%",
            justifyContent: "flex-start",
            marginRight: "25%",
          }}
        >
          <Grid container spacing={2} direction="row" justifyContent="center">
            <Paper
              elevation={4}
              style={{
                marginTop: "2%",
                padding: "0.6%",
                textAlign: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: 20,
                width: "calc(100% - 3%)",
                marginBottom: "4%",
                justifyContent: "space-around",
                alignItems: "center",
                padding: "2%",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                  My Banks
                </Typography>
                {userType === "ADMIN" && (
                  <IconButton
                    onClick={handleDialogOpen}
                    style={{ cursor: "pointer" }}
                  >
                    <AddCardIcon />
                  </IconButton>
                )}
              </Grid>

              {/* Existing Cards Rendering Logic */}
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {cardsData.map((card, index) => (
                  <Card
                    key={index}
                    style={{
                      backgroundColor: card.color,
                      width: activeCardIndex === index ? "25%" : "12%",
                      borderRadius: "20px",
                      display: "flex",
                      flexDirection:
                        activeCardIndex === index ? "column" : "column",
                      alignItems:
                        activeCardIndex === index ? "flex-start" : "center",
                      textAlign: activeCardIndex === index ? "start" : "center",
                      padding: "10px",
                      marginBottom: "16px",
                      marginTop: "16px",
                      minHeight: "100%",
                      transition: "width 0.3 ease-in-out",
                    }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection:
                            activeCardIndex === index ? "row" : "column",
                        }}
                      >
                        <card.icon
                          style={{
                            marginRight:
                              activeCardIndex === index ? "10px" : "0",
                            color: "#FFFFFF",
                          }}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            color: "#FFFFFF",
                            fontSize: "14px",
                            fontWeight: "bold",
                            paddingLeft: activeCardIndex === index ? "5%" : "0",
                            marginTop: activeCardIndex === index ? "0" : "10px",
                          }}
                        >
                          {card.bankName}
                        </Typography>
                      </Grid>
                      {activeCardIndex === index && (
                        <>
                          <Typography
                            variant="body1"
                            style={{
                              marginTop: "5%",
                              color: "yellow",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            {card.company}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              color: "white",
                              fontSize: "15px",
                              paddingTop: "4%",
                              fontWeight: "bold",
                            }}
                          >
                            Account No: {card.accountNo}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              color: "white",
                              fontSize: "13px",
                              paddingTop: "2%",
                            }}
                          >
                            IFSC: {card.ifsc}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{
                              color: "white",
                              fontSize: "13px",
                              paddingTop: "2%",
                            }}
                          >
                            Branch: {card.branch}
                          </Typography>
                        </>
                      )}
                      {activeCardIndex !== index && (
                        <Button
                          variant="outlined"
                          onClick={() => handleViewDetails(index)}
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            color: "white",
                            border: "1px solid white",
                            marginTop: "auto",
                            alignSelf: "center",
                            fontSize: "10px",
                          }}
                        >
                          View Details
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                ))}
              </Grid>

              {/* Dialog for adding a new bank */}
              <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Add New Bank</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Enter Bank Name"
                    name="bankName"
                    fullWidth
                    value={formData.bankName}
                    onChange={handleInputChange}
                    margin="dense"
                  />
                  {/* Bank Image Upload */}
                  <Button
                    variant="outlined"
                    component="label"
                    style={{ marginBottom: "16px" }}
                  >
                    Upload Bank Image
                    <input type="file" hidden onChange={handleImageUpload} />
                  </Button>
                  <TextField
                    label="Company Name"
                    name="companyName"
                    fullWidth
                    value={formData.companyName}
                    onChange={handleInputChange}
                    margin="dense"
                  />
                  <TextField
                    label="Account No."
                    name="accountNo"
                    fullWidth
                    value={formData.accountNo}
                    onChange={handleInputChange}
                    margin="dense"
                  />
                  <TextField
                    label="IFSC Code"
                    name="ifsc"
                    fullWidth
                    value={formData.ifsc}
                    onChange={handleInputChange}
                    margin="dense"
                  />
                  <TextField
                    label="Branch"
                    name="branch"
                    fullWidth
                    value={formData.branch}
                    onChange={handleInputChange}
                    margin="dense"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Container>

        {/* <Grid >
                    <Grid style={{backgroundColor:'red'}}>
                        <Typography>
                            Hello
                        </Typography>
                    </Grid>
                </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
