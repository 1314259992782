import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Dialog,
  DialogContent,
  Card,
  CardContent,
  FormLabel,
} from "@mui/material";

const NewElectricity2 = () => {
  const [electricityBoards, setElectricityBoards] = useState([]); // To store API response
  const [selectedBoard, setSelectedBoard] = useState("");
  const [consumerNumber, setConsumerNumber] = useState("");
  const [billingUnit, setBillingUnit] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [electricityInfo, setElectricityInfo] = useState(null);

  // Placeholder data for modal, replace with actual data from another API if needed
  const [customerName, setCustomerName] = useState("John Doe");
  const [amount, setAmount] = useState("₹500");
  const [walletBalance, setWalletBalance] = useState("₹1000");
  const [inputLabel, setInputLabel] = useState(""); // For dynamic label
  const [consumerInput, setConsumerInput] = useState(""); // For user input
  const [inputFields, setInputFields] = useState([]); // Store dynamic fields and labels
  const [formValues, setFormValues] = useState({}); // Store user input for all fields

  useEffect(() => {
    // Fetch electricity boards from API
    const fetchElectricityBoards = async () => {
      try {
        const response = await fetch(
          "https://api.ipaisa.co.in/myapp/auth/getAllElecServicePro",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer YOUR_ACCESS_TOKEN_HERE",
            },
          }
        );
        const data = await response.json();
        // Assuming the API returns an array of boards with `name` and `id`
        const boards = data.map((board) => ({
          label: board.name,
          value: board.id,
        }));
        setElectricityBoards(data); // Save the response to state
      } catch (error) {
        console.error("Error fetching electricity boards:", error);
      }
    };

    fetchElectricityBoards();
  }, []);

  const handleProceed = async () => {
    try {
      // Ensure required fields are filled
      if (!selectedBoard) {
        alert("Please select an electricity board.");
        return;
      }

      const selectedData = electricityBoards.find(
        (board) => board.opcode === selectedBoard
      );

      if (!selectedData) {
        alert("Invalid board selected.");
        return;
      }

      const { mplanShortcut } = selectedData;
      const tel = formValues["mn"]; // Assuming 'mn' is the Consumer Number field key

      if (!tel) {
        alert("Please fill the Consumer Number.");
        return;
      }

      const operator = selectedData.mplanShortcut;
      const token = localStorage.getItem("jwtToken");
      const url = `https://api.ipaisa.co.in/myapp/auth/getElectricityInfo?tel=${tel}&operator=${operator}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Replace with your token
      };

      const response = await fetch(url, {
        method: "GET",
        headers,
        // body: JSON.stringify({ mplanShortcut }), // Add any other required payload if needed
      });

      if (!response.ok) {
        throw new Error("Failed to fetch electricity info.");
      }

      const result = await response.json();
      if (result.status === 1 && result.records?.length > 0) {
        setElectricityInfo(result.records[0]);
      } else {
        alert("No records found.");
      }
      console.log("API Response:", result);
      alert("Electricity info fetched successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error fetching electricity info. Please try again.");
    }
  };
  const handleCancel = () => {
    setElectricityInfo(null);
    setFormValues({});
  };
  const handleBoardChange = (e) => {
    const selectedOpcode = e.target.value;
    setSelectedBoard(selectedOpcode);

    // Find the selected board in the fetched data
    const selectedData = electricityBoards.find(
      (board) => board.opcode === selectedOpcode
    );

    // Dynamically set input fields based on `inputParams`
    if (selectedData?.inputParams) {
      const fields = Object.entries(selectedData.inputParams).map(
        ([key, label]) => ({ key, label })
      );
      setInputFields(fields);

      // Initialize form values for the dynamic fields
      const initialValues = fields.reduce((acc, field) => {
        acc[field.key] = ""; // Initialize each field's value as empty
        return acc;
      }, {});
      setFormValues(initialValues);
    } else {
      setInputFields([]);
      setFormValues({});
    }
  };

  // Handle user input for dynamic fields
  const handleInputChange = (key, value) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handlePayBill = async () => {
    if (!selectedBoard) {
      alert("Please select an electricity board.");
      return;
    }

    // Find the selected board details
    const selectedData = electricityBoards.find(
      (board) => board.opcode === selectedBoard
    );

    if (!selectedData) {
      alert("Invalid board selected.");
      return;
    }

    const mn = formValues["mn"]; // Consumer number field key
    if (!mn) {
      alert("Please fill in the required consumer number.");
      return;
    }

    const url = "https://api.ipaisa.co.in/myapp/auth/billPay/recharge";
    const token = localStorage.getItem("jwtToken"); // Use token from localStorage

    const requestBody = {
      mn: mn, // Consumer number
      amt: electricityInfo?.Billamount || amount, // Amount to pay
      opCode: selectedData.opcode, // Provider name from mplanShortcut
      type: "Electricity recharge",
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to process payment");
      }

      const data = await response.json();
      console.log("Payment Success:", data);
      alert("Payment successful!");
      handleClose(); // Close the dialog after successful payment
    } catch (error) {
      console.error("Payment Error:", error);
      alert("Payment failed. Please try again.");
    }
  };

  return (
    <Box style={{ padding: "2rem" }}>
      <FormLabel
        component="legend"
        style={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
      >
        Electricity Bill Payment
      </FormLabel>

      <Grid container spacing={2} style={{ marginTop: "1%" }}>
        {/* Select Electricity Board */}
        <Grid item md={4} xs={12}>
          <TextField
            select
            label="Select Electricity Board"
            fullWidth
            value={selectedBoard}
            onChange={handleBoardChange}
            margin="normal"
            variant="outlined"
          >
            {electricityBoards.map((board) => (
              <MenuItem key={board.id} value={board.opcode}>
                {board.providerName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Render Dynamic Input Fields */}
        {inputFields.map((field) => (
          <Grid item md={4} xs={12} key={field.key}>
            <TextField
              label={field.label}
              fullWidth
              value={formValues[field.key] || ""}
              onChange={(e) => handleInputChange(field.key, e.target.value)}
              margin="normal"
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>

      {/* Proceed Button */}
      <Box
        style={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {!electricityInfo ? (
          <Button
            style={{
              color: "white",
              backgroundColor: "#027BFE",
              textTransform: "none",
              fontSize: "16px",
              width: "16%",
            }}
            onClick={handleProceed}
            disabled={processing}
          >
            {processing ? "Processing..." : "Proceed"}
          </Button>
        ) : null}
        {electricityInfo && (
          <>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <FormLabel
                  component="legend"
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "black",
                  }}
                >
                  Customer Details
                </FormLabel>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Customer Name"
                  value={electricityInfo.CustomerName}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Bill Amount"
                  value={electricityInfo.Billamount}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Bill Date"
                  value={electricityInfo.Billdate}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Due Date"
                  value={electricityInfo.Duedate}
                  fullWidth
                  disabled
                  variant="outlined"
                />
              </Grid>

              {/* Proceed Button */}

              <Grid
                item
                sx={{ display: "flex", justifyContent: "flex-end" }}
                spacing={2}
              >
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#f44336",
                    textTransform: "none",
                    fontSize: "16px",
                    width: "12%",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#4caf50",
                    textTransform: "none",
                    fontSize: "16px",
                    width: "12%",
                  }}
                  onClick={handlePayBill}
                >
                  Pay
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {/* Payment Modal */}
        <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
          <DialogContent>
            <Grid container spacing={4}>
              {/* Left Section: Payment Details */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    border: "1px solid #D9D9D9",
                    borderRadius: "12px",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    Confirm your payment
                  </Typography>

                  {/* Consumer Number */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Consumer Number
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {consumerNumber}
                    </Typography>
                  </Grid>

                  {/* Customer Name */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Customer Name
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {customerName}
                    </Typography>
                  </Grid>

                  {/* Amount */}
                  <Grid container justifyContent="space-between" mb={2}>
                    <Typography variant="body1" color="textSecondary">
                      Amount
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {amount}
                    </Typography>
                  </Grid>

                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      color: "white",
                      backgroundColor: "#027BFE",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Proceed to Pay
                  </Button>
                </Box>
              </Grid>

              {/* Right Section: Wallet Info */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "#027BFE",
                    color: "white",
                  }}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography variant="h6" fontWeight="bold">
                      Wallet Balance
                    </Typography>
                    <Typography variant="h4" fontWeight="bold" mt={1}>
                      {walletBalance}
                    </Typography>
                    <Button
                      sx={{
                        mt: 3,
                        backgroundColor: "white",
                        color: "#027BFE",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Update Wallet
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default NewElectricity2;
