import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  IconButton,
  TextField,
  Box,
  Typography,
  InputBase,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import axios from "axios";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2pdf from "html2pdf.js";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const CompeletedTrans = () => {
  const [completeData, setCompleteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();
  const [statusCodeValue, setStatusCodeValue] = useState(null); // State to manage status code

  const handleSearch = () => {
    setPage(0); // Reset to the first page on new search

    // If searchQuery is empty or only whitespace, call the API with page=0 and rowsPerPage=10
    if (!searchQuery.trim()) {
      fetchCompleteData(0, fromDate, toDate, "", 10); // Call API with no search query
    } else {
      fetchCompleteData(0, fromDate, toDate, searchQuery, rowsPerPage); // Call API with search query
    }
  };

  // Handle search input change (no API call here)
  const handleSearchInput = (event) => {
    const updatedSearchQuery = event.target.value; // Get the updated search query value
    setSearchQuery(updatedSearchQuery); // Update search query state

    // Check if the search query length becomes 0 (i.e., the input is cleared)
    if (updatedSearchQuery.length === 0) {
      // Fetch data with default parameters when search query is cleared
      fetchCompleteData(0, "", "", "", rowsPerPage);
    }
  };

  // Function to fetch completed data from the API
  const fetchCompleteData = async (
    page,
    fromDate,
    toDate,
    searchQuery,
    rowsPerPage
  ) => {
    setLoading(true); // Set loading to true before making the API call

    const token = localStorage.getItem("jwtToken");

    // Build the URL parameters conditionally
    let url = "https://api.ipaisa.co.in/myapp/admin/payinCompletedTxns?";

    // Add search query if available
    if (searchQuery.trim()) {
      url += `searchBy=${searchQuery}&`;
    }

    // Add fromDate if available
    if (fromDate) {
      url += `fromDate=${fromDate}&`;
    }

    // Add toDate if available
    if (toDate) {
      url += `toDate=${toDate}&`;
    }

    // Add pagination parameters (page and rowsPerPage)
    url += `page=${page}&rowsPerPage=${rowsPerPage}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (
        response.data.statusCodeValue === 204 ||
        response.data.body.message === "NO_CONTENT"
      ) {
        // Handle the case when no content is returned
        console.log("No content available");
        setCompleteData([]); // Clear the data state if no content
        setStatusCodeValue(response.data.statusCodeValue);
      } else {
        setCompleteData(response.data.body.data); // Set the data to the state if content is available
        setTotalRecords(response.data.body.totalRecords || 0); // Assuming totalRecords is provided in the response
      }
    } catch (error) {
      console.error("Error fetching completed transactions:", error);
      setCompleteData([]); // Optional: Clear data in case of error
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    fetchCompleteData(page, fromDate, toDate, searchQuery, rowsPerPage); // Fetch data on component mount or when page/filters change
  }, [page]); // Run when these dependencies change

  const handleClear = () => {
    setFromDate("");
    setSearchQuery("");
    setToDate("");
    setPage(0); // Reset to first page
    fetchCompleteData(0, "", "", "", rowsPerPage); // Fetch data with default params when cleared
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage); // Update the page state
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page when rows per page changes
  };

  const handleDownloadInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.txnid}`);

    if (!receiptElement) {
      console.error("Receipt element not found:", `receipt-${data.txnid}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5], // top, left, bottom, right in inches
      filename: `CompletedTransactions Receipt-${data.txnid}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        orientation: "landscape",
        format: "a4",
        margin: 0.5,
      },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  return (
    <Container style={{ paddingBottom: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Reports
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Completed Transactions Report
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "1%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Completed Transactions Report
        </Typography>
      </Grid>
      <Paper
        style={{
          boxShadow: "none",
          padding: "2rem",
          borderRadius: "8px",
          marginTop: "2%",
          boxShadow: "none",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="From Date"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TextField
              label="To Date"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Search and Buttons */}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            {/* Search Field */}
            <Box
              sx={{
                display: "flex",
                flex: 1,
                maxWidth: { xs: "100%", sm: "50%" }, // Dynamic width for responsiveness
                borderRadius: "10px",
                border: "1px solid rgba(0, 0, 0, 0.3)",
                padding: "0.5rem",
              }}
            >
              <InputBase
                fullWidth
                placeholder="Search"
                value={searchQuery} // Bind the value of search input to the state
                onChange={handleSearchInput} // Only update searchQuery, no API call here
              />
              <IconButton color="primary" onClick={handleSearch}>
                {" "}
                {/* Trigger search when icon is clicked */}
                <SearchIcon />
              </IconButton>
            </Box>

            {/* Action Buttons */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap", // Wrap buttons on smaller screens
                gap: "1rem",
              }}
            >
              <Button
                onClick={handleSearch} // Trigger search when search button is clicked
                sx={{
                  backgroundColor: "#027BFE",
                  color: "white",
                  height: "56px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: { xs: "100%", sm: "120px" }, // Responsive width
                }}
              >
                Search
              </Button>
              <Button
                onClick={handleClear} // Reset values and call API when clicked
                sx={{
                  backgroundColor: "gray",
                  color: "white",
                  height: "56px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: { xs: "100%", sm: "120px" }, // Responsive width
                }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "3%",
              borderRadius: "12px 12px 0px 0px",
              boxShadow: "none",
            }}
          >
            <Table>
              <TableHead sx={{ bgcolor: "#027BFE" }}>
                <TableRow>
                  {[
                    "Sr. No.",
                    "Txn ID",
                    "EasePay ID",
                    "UserID",
                    "Payment Type",
                    "Payment By",
                    ,
                    "Date",
                    "PayIn Amount",
                    "Transfer Amount",
                    "Status",
                    "Settlement",
                  ].map((header) => (
                    <TableCell
                      align="start"
                      key={header}
                      sx={{ color: "white" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {completeData.length > 0 ? (
                  completeData.map((row, index) => (
                    <TableRow
                      key={index}
                      className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell>{row.txnid || "N/A"}</TableCell>
                      <TableCell>{row.easepayid || "N/A"}</TableCell>
                      <TableCell>{row.userId || "N/A"}</TableCell>
                      <TableCell>{row.cardType}</TableCell>
                      {/* Conditional rendering for "Payment By" based on cardType */}
                      {row.cardType === "UPI" ? (
                        <TableCell>{row.upiVa}</TableCell> // Payment By UPI
                      ) : row.cardType === "Credit Card" ||
                        row.cardType === "Debit Card" ? (
                        <TableCell>{row.cardnum}</TableCell> // Payment By Card Number
                      ) : (
                        <TableCell>N/A</TableCell> // Handle other card types if necessary
                      )}
                      <TableCell>
                        {row.addedon
                          ? new Date(row.addedon).toLocaleString()
                          : "N/A"}
                      </TableCell>
                      <TableCell>{row.netAmountDebit || "N/A"}</TableCell>
                      <TableCell>{row.amount || "N/A"}</TableCell>
                      {/* <TableCell>{row.status || 'N/A'}</TableCell> */}
                      <TableCell
                        align="start"
                        style={{
                          color:
                            row.status.toLowerCase() === "success"
                              ? "green"
                              : row.status.toLowerCase() === "refund"
                                ? "ad"
                                : row.status.toLowerCase() === "pending"
                                  ? "orange"
                                  : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {row.status.toLowerCase() === "success"
                          ? "Successful"
                          : row.status.toLowerCase() === "refund"
                            ? "Refund"
                            : row.status.toLowerCase() === "pending"
                              ? "Pending"
                              : "Failed"}
                      </TableCell>
                      <TableCell>{row.settlement || "N/A"}</TableCell>
                      {/* <TableCell align="center">
                                            <DownloadIcon1
                                                onClick={() => handleDownloadInvoice(row)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </TableCell>
                                        <Grid style={{ position: 'absolute', left: '-9999px', top: '0px' }}>
                                            <Grid id={`receipt-${row.txnid}`}>
                                                <CompletedTransInvoice data={row} />
                                            </Grid>
                                        </Grid> */}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecords} // Total number of records fetched
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            nextIconButtonProps={{
              disabled:
                (page + 1) * rowsPerPage >= totalRecords ||
                statusCodeValue === 204,
            }}
            backIconButtonProps={{
              disabled: page === 0,
            }}
          />
        </>
      )}
    </Container>
  );
};

export default CompeletedTrans;
