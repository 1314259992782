import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form } from "formik";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  MenuItem,
  Container,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { fetchWalletBalance } from "../../../redux/actions/walletActions";
import { fetchBankDetails } from "../../../redux/actions/bankActions";
import WalletBalanceComponent from "../../Balance/WalletBalanceComponent";
import PayoutPopup from "../../Balance/PayoutPopup";
import PayoutFailedPopup from "../../Balance/PayoutFailedPopup";
import InsufficiantB from "../../Balance/InsufficiantB";
import SetPasswordModal from "../../IpaisaMpin/SetIPMpin";
import IpinModal from "../../IpaisaMpin/IpinModal";

const SinglePayout = () => {
  const [showSuccessPop, setShowSuccessPop] = useState(false);
  const [showFailedPop, setShowFailedPop] = useState(false);
  const [insufficiantB, setInsufficiantB] = useState(false);
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);
  const [isIpinModalOpen, setIpinModalOpen] = useState(false);
  const [pendingSubmission, setPendingSubmission] = useState(null);
  const [loading, setLoading] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(null); // Add state for Autocomplete value

  const dispatch = useDispatch();
  const walletBalanceFromStore = useSelector((state) => state.wallet.balance);
  const bankDetails = useSelector((state) => state.bank.bankDetails);

  useEffect(() => {
    dispatch(fetchWalletBalance());
    dispatch(fetchBankDetails());
  }, [dispatch]);
  const payoutModes = [
    { value: "NEFT", label: "NEFT" },
    { value: "RTGS", label: "RTGS" },
    { value: "IMPS", label: "IMPS" },
    // Add more options as needed
  ];
  const handleCloseModal = () => {
    setIpinModalOpen(false);
    setOpenSetPasswordModal(false); // Close SetPassword modal
  };
  const handleClose = () => {
    setShowSuccessPop(false);
    setShowFailedPop(false);
    setInsufficiantB(false);
  };

  const PayoutSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    payeeaccountNumber: Yup.string().required("Account number is required"),
    bankIfsc: Yup.string().required("Bank IFSC is required"),
    transferMode: Yup.string().required("Transfer mode is required"),
    transferAmount: Yup.number().required("Transfer amount is required"),
    remarks: Yup.string(),
  });

  const handlePayClick = (values, { resetForm }) => {
    setPendingSubmission({ values, resetForm });
    if (localStorage.getItem("isTxnMpinSet") === "False") {
      setOpenSetPasswordModal(true);
    } else {
      setIpinModalOpen(true);
    }
  };

  const handleIpinValid = async (ipin) => {
    setLoading(false);
    setIpinModalOpen(false);
    if (pendingSubmission) {
      await handleSubmitAfterIpin(pendingSubmission);
      setPendingSubmission(null);
    }
  };

  const handleSubmitAfterIpin = async ({ values, resetForm }) => {
    setLoading(true);
    if (values.transferAmount > walletBalanceFromStore) {
      setInsufficiantB(true);
      setLoading(false);
      return;
    }

    try {
      const location = await getLocation();
      const payload = {
        ...values,
        payeraccountNumber: "120664700000021",
        latitude: location.latitude.toFixed(4),
        longitude: location.longitude.toFixed(4),
      };

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/instantpay/payout",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setShowSuccessPop(true);
        dispatch(fetchWalletBalance());
        resetForm(); // Clear the form after a successful payout
        setAutocompleteValue(null); // Clear Autocomplete value
      } else if (response.status === 402) {
        setInsufficiantB(true);
      } else {
        setShowFailedPop(true);
      }
    } catch (error) {
      console.error("Payout failed:", error);
      setShowFailedPop(true);
    } finally {
      setLoading(false);
    }
  };

  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position.coords),
          (error) => reject(error)
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  return (
    <>
      <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
        <Grid sx={{ maxWidth: "400px", width: "100%" }}>
          <WalletBalanceComponent walletBalance={walletBalanceFromStore} />
        </Grid>

        <Grid sx={{ mt: "5%" }}>
          <Formik
            initialValues={{
              name: "",
              payeeaccountNumber: "",
              bankIfsc: "",
              transferMode: "",
              transferAmount: "",
              remarks: "",
            }}
            validationSchema={PayoutSchema}
            onSubmit={(values, { resetForm }) =>
              handlePayClick(values, { resetForm })
            } // Pass resetForm here
          >
            {({
              errors,
              touched,
              setFieldValue,
              isValid,
              isSubmitting,
              resetForm,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={bankDetails}
                      getOptionLabel={(option) =>
                        `${option.accountNumber} - ${option.name}`
                      }
                      value={autocompleteValue} // Bind Autocomplete to state
                      onChange={(event, newValue) => {
                        setAutocompleteValue(newValue); // Update state on change
                        if (newValue) {
                          setFieldValue(
                            "payeeaccountNumber",
                            newValue.accountNumber
                          );
                          setFieldValue("bankIfsc", newValue.ifsc);
                          setFieldValue("name", newValue.name);
                        } else {
                          setFieldValue("payeeaccountNumber", "");
                          setFieldValue("bankIfsc", "");
                          setFieldValue("name", "");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Account Number"
                          variant="outlined"
                          error={
                            touched.payeeaccountNumber &&
                            Boolean(errors.payeeaccountNumber)
                          }
                          helperText={
                            touched.payeeaccountNumber &&
                            errors.payeeaccountNumber
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="bankIfsc"
                      label="Bank IFSC"
                      variant="outlined"
                      fullWidth
                      error={touched.bankIfsc && Boolean(errors.bankIfsc)}
                      helperText={touched.bankIfsc && errors.bankIfsc}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="transferMode"
                      label="Transfer Mode"
                      select
                      variant="outlined"
                      fullWidth
                      error={
                        touched.transferMode && Boolean(errors.transferMode)
                      }
                      helperText={touched.transferMode && errors.transferMode}
                    >
                      {payoutModes.map((mode) => (
                        <MenuItem key={mode.value} value={mode.value}>
                          {mode.label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="transferAmount"
                      label="Transfer Amount"
                      variant="outlined"
                      type="number"
                      fullWidth
                      error={
                        touched.transferAmount && Boolean(errors.transferAmount)
                      }
                      helperText={
                        touched.transferAmount && errors.transferAmount
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      as={TextField}
                      name="remarks"
                      label="Remarks"
                      variant="outlined"
                      fullWidth
                      error={touched.remarks && Boolean(errors.remarks)}
                      helperText={touched.remarks && errors.remarks}
                    />
                  </Grid>
                </Grid>
                <Box
                  display="flex"
                  justifyContent="end"
                  gap={1}
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                  style={{ marginTop: "3%" }}
                >
                  <Button
                    onClick={() => {
                      resetForm(); // Reset Form
                      setAutocompleteValue(null); // Clear Autocomplete value
                    }}
                    disabled={isSubmitting}
                    sx={
                      {
                        /* your styles */
                      }
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid}
                    sx={
                      {
                        /* your styles */
                      }
                    }
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    ) : (
                      "Pay"
                    )}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Container>
      {showSuccessPop && <PayoutPopup handleClose={handleClose} />}
      {showFailedPop && <PayoutFailedPopup handleClose={handleClose} />}
      {insufficiantB && <InsufficiantB handleClose={handleClose} />}
      {openSetPasswordModal && (
        <SetPasswordModal
          open={openSetPasswordModal}
          onClose={handleCloseModal}
          onMpinValid={handleIpinValid}
        />
      )}
      {isIpinModalOpen && (
        <IpinModal
          open={isIpinModalOpen}
          onClose={handleCloseModal}
          onMpinValid={handleIpinValid}
        />
      )}
    </>
  );
};

export default SinglePayout;
