import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { Formik, Form } from "formik";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import iPaisaLogo from "../Assets/iPaisaLogo/iPaisaLogo.jpg"; // Adjust this path if needed
import { Pagination } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "& > *": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  creditText: {
    color: "green",
  },
  debitText: {
    color: "red",
  },
}));

const NewFTRTable = () => {
  const classes = useStyles();
  const [debitData, setDebitData] = useState([]);
  const [creditData, setCreditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const validationSchema = Yup.object({
    fromDate: Yup.date().required("Required"),
    toDate: Yup.date().required("Required"),
  });

  const fetchDebitData = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/wtransReportDebt",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDebitData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching debit data:", error);
      setError(error.message);
    }
  };

  const fetchCreditData = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/wtransReportCred",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCreditData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching credit data:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchDebitData();
    fetchCreditData();
    setLoading(false);
  }, []);

  const handleSearch = (values) => {
    const { fromDate, toDate } = values;
    const from = new Date(fromDate);
    const to = new Date(toDate);

    if (Array.isArray(debitData) && Array.isArray(creditData)) {
      const filteredDebitData = debitData.filter(
        (item) => new Date(item.date) >= from && new Date(item.date) <= to
      );
      const filteredCreditData = creditData.filter(
        (item) => new Date(item.date) >= from && new Date(item.date) <= to
      );
      setFilteredData([...filteredDebitData, ...filteredCreditData]);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.addImage(iPaisaLogo, "PNG", 10, 10, 30, 30);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Transaction Statement", 50, 20);

    const tableColumn = ["Date", "Transaction Details", "Type", "Amount"];
    const tableRows = filteredData.map((row) => [
      new Date(row.date).toLocaleString(),
      row.transactionType === "DEBIT"
        ? `Paid to ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nPaid by ${row.senderNumber}`
        : `Received from ${row.senderName}\nTransaction ID ${row.tranxid}\nUTR No. ${row.remark}\nCredited to ${row.senderNumber}`,
      row.transactionType,
      `${row.amount.toFixed(2)}`,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 50,
      styles: {
        fontSize: 10,
        cellPadding: 3,
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontSize: 12,
      },
    });

    doc.save("Transaction_Statement.pdf");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const uniqueData = filteredData.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.tranxid === item.tranxid)
  );

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Report
        </Typography>
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Fund Transfer Report
        </Typography>
      </Grid>

      <Grid style={{ marginTop: "3%" }}>
        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
          <Formik
            initialValues={{ fromDate: "", toDate: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSearch}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: { xs: "0%", md: "1%" } }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="from-date"
                      name="fromDate"
                      label="From Date"
                      type="date"
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={touched.fromDate && Boolean(errors.fromDate)}
                      helperText={touched.fromDate && errors.fromDate}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="to-date"
                      name="toDate"
                      label="To Date"
                      type="date"
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      error={touched.toDate && Boolean(errors.toDate)}
                      helperText={touched.toDate && errors.toDate}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: { xs: "0%", md: "1%" },
                    }}
                  >
                    <Button
                      sx={{
                        height: "50px",
                        fontSize: "16px",
                        textTransform: "none",
                        width: { xs: "100%", md: "18%" },
                        borderRadius: "8px",
                        backgroundColor: "#027BFE",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#027BFE",
                          color: "#FFFFFF",
                        },
                      }}
                      type="submit"
                      fullWidth
                    >
                      Search
                    </Button>
                    <Button
                      onClick={generatePDF}
                      style={{
                        textTransform: "none",
                        backgroundColor: "#027BFE",
                        color: "#FFFFFF",
                        borderRadius: "10px",
                        marginLeft: "1rem",
                      }}
                    >
                      <DownloadIcon />
                      Download PDF
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Grid>

      {loading && (
        <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
          Loading...
        </Typography>
      )}
      {error && (
        <Typography color="error" sx={{ textAlign: "center", mt: 2 }}>
          {error}
        </Typography>
      )}
      {!loading && !error && (
        <Grid sx={{ marginTop: "3%" }}>
          <TableContainer
            component={Paper}
            sx={{
              marginTop: "2%",
              maxWidth: "100%",
              overflowX: "auto",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "#027BFE", color: "white" }}>
                <TableRow>
                  {[
                    "Sr. No.",
                    "Sender Name",
                    "Sender ID",
                    "Transaction ID",
                    "Amount",
                    "Date",
                    "Remark",
                    "Sender Number",
                    "Transaction Type",
                  ].map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        color: "white",
                        fontSize: { xs: "9px", sm: "10px", md: "11px" },
                      }}
                      align="start"
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={row.tranxid}
                      className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                    >
                      <TableCell
                        align="start"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {row.senderName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {row.senderId}
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {row.tranxid}
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >{`₹${row.amount.toFixed(2)}`}</TableCell>
                      <TableCell
                        align="start"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {new Date(row.date).toLocaleDateString()}
                      </TableCell>
                      <TableCell
                        align="start"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {row.remark}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontSize: { xs: "9px", sm: "10px", md: "11px" } }}
                      >
                        {row.senderNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: { xs: "9px", sm: "10px", md: "11px" },
                          color:
                            row.transactionType === "CREDIT" ? "green" : "red",
                        }}
                      >
                        {row.transactionType}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(uniqueData.length / rowsPerPage)}
            page={page + 1}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              marginTop: 2,
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "45%",
            }}
          />
        </Grid>
      )}
    </Container>
  );
};

export default NewFTRTable;
