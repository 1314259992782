import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CardMedia,
} from "@mui/material";

import BBPSLogo from "../Assets/BBPSLogo/NewBBPS.png";
import logo from "../Assets/login/iPaisaLogo2.png";
import { NewBBPSIcon } from "../../res/NewWebIcons";

const GasCylinderBooking = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [apiError, setApiError] = useState("");
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null); // To store selected operator details

  useEffect(() => {
    // Fetch operator options when the component mounts
    const fetchOperatorOptions = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.get(
          "https://api.ipaisa.co.in/myapp/auth/getAllGasServicePro",
          { headers: { Authorization: `${token}` } }
        );

        if (response.data && Array.isArray(response.data)) {
          setOperatorOptions(response.data);
        } else {
          setApiError("Invalid data format received from the API.");
        }
      } catch (error) {
        console.error("API Error:", error);
        setApiError("Failed to fetch operator data. Please try again later.");
      }
    };

    fetchOperatorOptions();
  }, []);

  const formik = useFormik({
    initialValues: {
      tel: "",
      operator: "",
    },
    validationSchema: Yup.object({
      tel: Yup.string()
        .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number")
        .notOneOf(
          [
            "0000000000",
            "1111111111",
            "2222222222",
            "3333333333",
            "4444444444",
            "5555555555",
            "6666666666",
            "7777777777",
            "8888888888",
            "9999999999",
          ],
          "Mobile number cannot be all the same digit"
        )
        .required("Contact Number is required"),
      operator: Yup.string().required("Operator is required"),
    }),
    onSubmit: async (values) => {
      if (!selectedOperator) {
        setApiError("Please select a valid operator.");
        return;
      }

      const payload = {
        tel: values.tel,
        operator: selectedOperator.mplanShortcut, // Pass mplanShortcut
        opcode: selectedOperator.opcode, // Pass opcode
      };

      try {
        const token = localStorage.getItem("jwtToken");
        const response = await axios.get(
          "https://api.ipaisa.co.in/myapp/auth/getGasbillInfo",
          {
            params: {
              tel: payload.tel,
              operator: payload.operator,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("API Response:", response.data);
        setSubmitSuccess(true);
      } catch (error) {
        console.error("API Error:", error);
        setApiError("Failed to submit booking. Please try again later.");
      }
    },
  });

  const handleOperatorChange = (event) => {
    const selected = operatorOptions.find(
      (option) => option.providerName === event.target.value
    );
    setSelectedOperator(selected);
    formik.setFieldValue("operator", event.target.value);
  };

  const inputHeight = "56px";

  return (
    <Container maxWidth="lg" style={{ marginTop: "12%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        bgcolor="#DDF1F5"
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: "4%",
              }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
                marginRight: { xs: "10px", md: "10px" },
              }}
            />
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", fontSize: "35px" }}
              gutterBottom
            >
              Quik Gas Booking Service
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: "20px", marginTop: "5%" }}
            >
              Effortlessly secure your fuel supply with swift and convenient gas
              booking services, ensuring uninterrupted energy for your needs.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography
                variant="h5"
                color="#027BFE"
                gutterBottom
                sx={{ fontWeight: "bold", fontSize: "30px" }}
              >
                Gas Booking Online
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <NewBBPSIcon />
              </Box>
              <form onSubmit={formik.handleSubmit}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={
                    formik.touched.operator && Boolean(formik.errors.operator)
                  }
                >
                  <InputLabel id="operator-label">Operator</InputLabel>
                  <Select
                    labelId="operator-label"
                    id="operator"
                    name="operator"
                    label="Operator"
                    value={formik.values.operator}
                    onChange={handleOperatorChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="">Select Operator</MenuItem>
                    {Array.isArray(operatorOptions) &&
                      operatorOptions.map((option) => (
                        <MenuItem
                          key={option.opcode}
                          value={option.providerName}
                        >
                          {option.providerName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={formik.touched.tel && Boolean(formik.errors.tel)}
                >
                  <TextField
                    id="tel"
                    name="tel"
                    label="Registered Contact Number"
                    variant="outlined"
                    value={formik.values.tel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={formik.touched.tel && formik.errors.tel}
                    InputProps={{
                      inputProps: {
                        maxLength: 10,
                        pattern: "[0-9]*",
                        title: "Please enter a 10-digit number",
                      },
                    }}
                  />
                </FormControl>
                <Button
                  fullWidth
                  type="submit"
                  style={{
                    marginTop: "4%",
                    backgroundColor: "#027BFE",
                    color: "white",
                    textTransform: "none",
                    height: inputHeight,
                    fontSize: 20,
                  }}
                >
                  Confirm
                </Button>
                {submitSuccess && (
                  <Typography
                    variant="body1"
                    style={{ marginTop: "0.5rem", color: "green" }}
                  >
                    Booking successfully submitted!
                  </Typography>
                )}
                {apiError && (
                  <Typography
                    variant="body1"
                    style={{ marginTop: "0.5rem", color: "red" }}
                  >
                    {apiError}
                  </Typography>
                )}
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GasCylinderBooking;
