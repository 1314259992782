import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AddUserDialog from "./AddUserDialog";
import "react-toastify/dist/ReactToastify.css";
import EditUserDialog from "./EditUserDialog";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Grid,
  TablePagination,
  Switch,
  IconButton,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { Visibility, Edit, Delete } from "@mui/icons-material";
import axios from "axios";
import ViewUserDialog from "./ViewUserDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  deletePartner,
  fetchPartners,
  togglePartnerStatus,
  updatePartners,
} from "../../redux/actions/partnerActions";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { makeStyles } from "@mui/styles";
import UserCreationSuccessPopup from "./UserCreationSuccessPopup";
import UserCreationFailedPopup from "./UserCreationFailedPopup";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#C1E0FB",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  middleName: Yup.string().required("Middle Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  dob: Yup.date().required("Date of Birth is required"),
  mobileNumber: Yup.string().required("Mobile Number is required"),
  alternateMobileNumber: Yup.string().required(
    "Alternate Mobile Number is required"
  ),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const CplistTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);

  // Pop-up state
  const [showSuccessPop, setShowSuccessPop] = useState(false);
  const [showFailedPop, setShowFailedPop] = useState(false);

  const handleClosePop = () => {
    setShowSuccessPop(false);
    setShowFailedPop(false);
  };

  // Pagination state
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // Dialog states
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);

  // Selected row states
  const [selectedRow, setSelectedRow] = useState(null);
  const [editRow, setEditRow] = useState(null);
  const [viewRow, setViewRow] = useState(null);

  // Search and user type states
  const [searchQuery, setSearchQuery] = useState("");
  const [userType, setUserType] = useState("");
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const storedUserType = localStorage.getItem("uType");
    setUserType(storedUserType);
  }, []);

  // New user state
  const [newUser, setNewUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    email: "",
    country: null,
    state: null,
    city: null,
  });
  const [addUserOpen, setAddUserOpen] = useState(false);

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle dialog open
  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleEditOpen = (row) => {
    setEditRow(row);
    setEditOpen(true);
  };

  const handleViewOpen = (row) => {
    setViewRow(row);
    setViewOpen(true);
  };

  const handleAddUserOpen = () => {
    setAddUserOpen(true);
  };

  // Handle dialog close
  const handleEditClose = () => {
    setEditOpen(false);
    setEditRow(null);
  };

  const handleViewClose = () => {
    setViewOpen(false);
    setViewRow(null);
  };

  // Handle saving edited user
  const handleSaveEdit = async (editedUser) => {
    const editedIndex = partners.findIndex(
      (user) => user.userid === editedUser.userid
    );
    if (editedIndex !== -1) {
      try {
        const token = localStorage.getItem("jwtToken");
        await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/updateUser",
          editedUser,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const newData = [...partners];
        newData[editedIndex] = editedUser;
        setPartners(newData); // Update local state

        // Dispatch the update user action if using Redux
        dispatch(updatePartners(editedUser));

        toast.success("User details updated successfully");
        // Optionally show success pop-up
        // setShowSuccessPop(true);
      } catch (error) {
        console.error("Error updating user:", error);
        toast.error("Error updating user details. Please try again later.");
        // Optionally show failed pop-up
        // setShowFailedPop(true);
      }
    } else {
      toast.error("Error updating user details");
      // Optionally show failed pop-up
      // setShowFailedPop(true);
    }
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === "") {
      toast.info("Please enter a search term.");
      return;
    }

    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/searchInUserList?roleid=2&searchBy=${encodeURIComponent(searchQuery)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCodeValue === 200 && response.data.body) {
        setPartners(response.data.body);
        console.log(partners);
        setTotalDataCount(response.data.totalCount || 0); // Update total count
      } else {
        setPartners([]); // Clear table if no results
        toast.info("No results found.");
      }
    } catch (error) {
      console.error("Error searching users:", error);
      toast.error("Error fetching search results. Please try again.");
    }
  };

  const fetchPartnersFromApi = async (pageNum) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/list?pageNumber=${pageNum}&roleid=2`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 200 && response.data.users) {
        setPartners(response.data.users);
        setIsLastPage(response.data.users.length < rowsPerPage);
        setTotalDataCount(response.data.length); // Assuming the API returns total records count
      } else if (response.data.status === 404) {
        toast.info(response.data.message);

        // If no data on the first page, navigate to the second page and check there
        if (pageNum === 0) {
          setPageNumber(1); // Move to second page if first page returns 404
          fetchPartnersFromApi(1); // Call the API for the second page
        } else {
          setPartners([]); // No data, set empty table
          setIsLastPage(true); // Set last page as true if no more data
        }
      }
    } catch (error) {
      console.error("Error fetching partners:", error);
      toast.error("Error fetching partners. Please try again later.");
    }
  };
  // Store page data to avoid redundant API calls on previous pages
  const pageDataCache = {};

  const handlePageChange = async (newPage) => {
    if (pageDataCache[newPage]) {
      setPartners(pageDataCache[newPage]); // Use cached data if available
    } else {
      await fetchPartnersFromApi(newPage);
      pageDataCache[newPage] = partners; // Cache data for the page
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPageNumber(0); // Reset to the first page
    fetchPartnersFromApi(0); // Fetch data for the new rowsPerPage
  };

  // Fetch partners when the component mounts
  useEffect(() => {
    fetchPartnersFromApi(pageNumber);
  }, [pageNumber, rowsPerPage]); // Fetch data whenever pageNumber or rowsPerPage changes

  const handleAddUser = (values) => {
    // Assuming you handle adding the user via API or state
    // After adding, refresh the partners list
    fetchPartnersFromApi();
    toast.success("User added successfully");
    setShowSuccessPop(true);
  };

  const handleAddUserClose = () => {
    setAddUserOpen(false);
    fetchPartnersFromApi(); // Refresh partners
  };

  // Handle input changes for forms
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editOpen) {
      setEditRow({ ...editRow, [name]: value });
    } else {
      setNewUser({ ...newUser, [name]: value });
    }
  };

  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [selectedUser, setSelectedUser] = useState(null); // Store selected user's details

  const handleOpenModal = (user) => {
    setSelectedUser(user); // Set the user to be deleted
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal without deleting
  };

  const handleDelete = () => {
    // Call the deletePartner action to handle deletion
    dispatch(deletePartner(selectedUser.userid));
    handleCloseModal(); // Close the modal after deletion
    fetchPartnersFromApi();
  };

  // Handle deleting a partner
  // const handleDelete = (cpId) => {
  //     // Call the deletePartner action, which handles the API call and Redux update
  //     dispatch(deletePartner(cpId));
  //     // Optionally, refresh the partners list after deletion
  //     fetchPartnersFromApi();
  // };

  // Handle closing view dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };
  const handleSearchQueryChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);

    if (value.trim() === "") {
      // If search query is cleared, fetch partners with default params
      fetchPartnersFromApi(0);
    }
  };

  // Handle toggling partner status
  const handleStatusToggle = async (cpId, currentStatus) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const newStatus = currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";

      const response = await axios.put(
        `https://api.ipaisa.co.in/myapp/admin/changeStatus/${cpId}`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Check the response status and show the appropriate toast message
      if (response.data.message === "true") {
        // Update the local state
        setPartners((prevPartners) =>
          prevPartners.map((partner) =>
            partner.userid === cpId
              ? { ...partner, status: newStatus }
              : partner
          )
        );

        toast.success(`User status changed to ${newStatus}`);
      } else {
        toast.error(`Error: ${response.data.status}`);
      }
    } catch (error) {
      console.error("Error toggling user status:", error);
      toast.error("Error toggling user status. Please try again later.");
    }
  };

  // Filter partners based on search query
  const filteredData = partners.filter((row) => {
    return Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const inputHeight = "56px";

  return (
    <Container
      style={{
        marginTop: "calc(3% + 56px)",
        maxWidth: "xl",
        backgroundColor: "#F4F5FA",
        height: "100vh",
      }}
    >
      <Box mt={4} mb={2}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Grid>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "22px",
                marginBottom: { xs: "4%", md: "0%" },
              }}
            >
              Channel Partners
            </Typography>
          </Grid>

          <Grid
            spacing={2}
            style={{ display: "flex", gap: "2%" }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <TextField
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchQueryChange} // Handle search query changes
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {userType !== "RETAILER" && (
              <Grid item>
                <Button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    height: inputHeight,
                  }}
                  onClick={handleAddUserOpen}
                >
                  <ControlPointIcon style={{ marginRight: 8 }} />
                  Add User
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <TableContainer component={Paper} style={{ marginTop: "2%" }}>
          <Table>
            <TableHead style={{ backgroundColor: "#027BFE" }}>
              <TableRow>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Sr.No
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  User ID
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Parent ID
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                  Name
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "start" }}>
                  Business Name
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Mobile Number
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Wallet Balance
                </TableCell>
                {userType === "ADMIN" && (
                  <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                    Status
                  </TableCell>
                )}
                <TableCell sx={{ color: "#FFFFFF", textAlign: "center" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.userid}
                    className={`${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell sx={{ textAlign: "center" }}>
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.userid}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.parentId}
                    </TableCell>
                    <TableCell sx={{ textAlign: "start" }}>
                      {`${row.firstName} ${row.middleName} ${row.lastName}`}
                    </TableCell>
                    <TableCell sx={{ textAlign: "start" }}>
                      {row.bussinessName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {row.mobileNumber}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {Number(row.walletBalance).toFixed(2)}
                    </TableCell>
                    {userType === "ADMIN" && (
                      <TableCell sx={{ textAlign: "center" }}>
                        <Switch
                          onChange={() =>
                            handleStatusToggle(row.userid, row.status)
                          }
                          checked={row.status === "ACTIVE"}
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "green",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "green",
                              },
                            "& .MuiSwitch-switchBase": {
                              color: "red",
                            },
                            "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                              backgroundColor: "red",
                            },
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>
                      <IconButton onClick={() => handleViewOpen(row)}>
                        <Visibility />
                      </IconButton>
                      {userType === "ADMIN" && (
                        <>
                          <IconButton onClick={() => handleEditOpen(row)}>
                            <Edit />
                          </IconButton>
                          <IconButton onClick={() => handleOpenModal(row)}>
                            <Delete />
                          </IconButton>

                          {/* Confirmation Modal */}
                          <Modal open={openModal} onClose={handleCloseModal}>
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                borderRadius: 2,
                              }}
                            >
                              <Typography variant="h6" component="h2">
                                Confirm Delete?
                              </Typography>
                              <Typography sx={{ mt: 2 }}>
                                Are you sure you want to delete the User Number{" "}
                                <b>{selectedUser?.mobileNumber}</b> with User
                                ID: <b>{selectedUser?.userid}</b>?
                              </Typography>

                              {/* Buttons for Confirm and Cancel actions */}
                              <Box
                                sx={{
                                  mt: 3,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={handleCloseModal}
                                  sx={{ mr: 2 }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  onClick={handleDelete}
                                >
                                  Delete
                                </Button>
                              </Box>
                            </Box>
                          </Modal>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalDataCount} // Total number of rows
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          onPageChange={(event, newPage) => {
            setPageNumber(newPage); // Set the new page number
            handlePageChange(newPage); // Fetch partners for the new page or use cached data
          }}
          onRowsPerPageChange={handleRowsPerPageChange}
          nextIconButtonProps={{ disabled: isLastPage }} // Disable if it's the last page
          backIconButtonProps={{ disabled: pageNumber === 0 }} // Disable if it's the first page
        />
      </Box>

      {/* Dialogs */}
      <AddUserDialog
        open={addUserOpen}
        handleClose={handleAddUserClose}
        handleAddUser={handleAddUser}
      />
      {editRow && (
        <EditUserDialog
          open={editOpen}
          handleClose={handleEditClose}
          user={editRow}
          handleSave={handleSaveEdit}
        />
      )}
      {viewRow && (
        <ViewUserDialog
          open={viewOpen}
          handleClose={handleViewClose}
          user={viewRow}
        />
      )}
      <ToastContainer />

      {/* Pop-ups */}
      {showSuccessPop && (
        <UserCreationSuccessPopup handleClose={handleClosePop} />
      )}
      {showFailedPop && (
        <UserCreationFailedPopup handleClose={handleClosePop} />
      )}
    </Container>
  );
};

export default CplistTable;
