import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Grid,
  TableFooter,
  TablePagination,
  CircularProgress,
  TextField,
  Box,
  InputBase,
  IconButton,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import html2pdf from "html2pdf.js";
import ReverseMoneyInvoice from "./ReverseMoneyInvoice";
import axios from "axios";

const useStyles = makeStyles(() => ({
  evenRow: {
    backgroundColor: "#D0EFFF",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
}));

const ReverseMoneyReports = () => {
  const classes = useStyles();
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [noData, setNoData] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [statusCodeValue, setStatusCodeValue] = useState(null); // State to manage status code
  const [page, setPage] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleSearchInput = (event) => {
    const updatedSearchQuery = event.target.value; // Get the updated search query value
    setSearchQuery(updatedSearchQuery); // Update search query state

    // Check if the search query length becomes 0 (i.e., the input is cleared)
    if (updatedSearchQuery.length === 0) {
      // Fetch data with default parameters when search query is cleared
      fetchCompleteData(0, "", "", "", rowsPerPage);
    }
  };

  const fetchCompleteData = async (
    page,
    fromDate,
    toDate,
    searchQuery,
    rowsPerPage
  ) => {
    setLoading(true); // Set loading to true before making the API call

    const token = localStorage.getItem("jwtToken"); // Retrieve the token from localStorage

    // Ensure searchQuery is a string and trim any spaces
    const safeSearchQuery = searchQuery ? searchQuery.trim() : ""; // Fallback to empty string if undefined/null

    // Build the URL parameters conditionally
    let url = "https://api.ipaisa.co.in/myapp/admin/rerseMoneyTxn?";

    // Add search query if available
    if (safeSearchQuery) {
      url += `searchBy=${safeSearchQuery}&`;
    }

    // Add fromDate if available
    if (fromDate) {
      url += `fromDate=${fromDate}&`;
    }

    // Add toDate if available
    if (toDate) {
      url += `toDate=${toDate}&`;
    }

    // Add pagination parameters (page and rowsPerPage)
    url += `page=${page}&rowsPerPage=${rowsPerPage}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add Authorization header
        },
      });

      // Check if the response is empty or no content is available
      if (
        response.data.statusCodeValue === 204 ||
        response.data.body.message === "No Records Found"
      ) {
        console.log("No content available or No Transactions Found");
        setTransactions([]); // Clear the data state if no content
        setStatusCodeValue(response.data.statusCodeValue);
      } else {
        setTransactions(response.data.body.data); // Set the data to the state if content is available
        setTotalRecords(response.data.body.totalRecords || 0); // Assuming totalRecords is provided in the response
      }
    } catch (error) {
      console.error("Error fetching reverse money transactions:", error);
      setTransactions([]); // Optional: Clear data in case of error
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage); // Corrected logic: Increments the page by 1 based on newPage
  };

  const handleSearch = () => {
    setCurrentPage(0); // Reset to the first page on new search
    fetchCompleteData(0, fromDate, toDate, searchQuery, rowsPerPage); // Call API with search query and date filters
  };

  const handleClear = () => {
    setFromDate("");
    setToDate("");
    setSearchQuery("");
    setCurrentPage(0); // Reset to first page
    fetchCompleteData(0, "", "", "", rowsPerPage); // Fetch data with default params when cleared
  };

  useEffect(() => {
    // Fetch data whenever currentPage or rowsPerPage change
    fetchCompleteData(currentPage, fromDate, toDate, searchQuery, rowsPerPage);
  }, [currentPage, rowsPerPage]); // Dependency array includes all necessary states

  const handleDownloadInvoice = async (data) => {
    const receiptElement = document.getElementById(`receipt-${data.tId}`);

    if (!receiptElement) {
      console.error("Receipt element not found:", `receipt-${data.tId}`);
      return;
    }

    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `Payout Receipt-${data.tId}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", orientation: "landscape", format: "a4" },
    };

    try {
      await html2pdf().from(receiptElement).set(opt).save();
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
  };

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Report
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Reverse Money Report
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "2%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Reverse Money Report
        </Typography>
      </Grid>

      <Paper
        sx={{
          padding: "2rem",
          borderRadius: "8px",
          marginTop: "2%",
          overflow: "hidden", // Prevent content overflow
          boxShadow: "none",
        }}
      >
        <Grid container spacing={2}>
          {/* Date Fields */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="From Date"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="To Date"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Search and Buttons */}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            {/* Search Field */}
            <Box
              sx={{
                display: "flex",
                flex: 1,
                maxWidth: { xs: "100%", sm: "50%" }, // Dynamic width for responsiveness
                borderRadius: "10px",
                border: "1px solid rgba(0, 0, 0, 0.3)",
                padding: "0.5rem",
              }}
            >
              <InputBase
                fullWidth
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchInput}
              />
              <IconButton color="primary" onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </Box>

            {/* Action Buttons */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap", // Wrap buttons on smaller screens
                gap: "1rem",
              }}
            >
              <Button
                onClick={handleSearch}
                sx={{
                  backgroundColor: "#027BFE",
                  color: "white",
                  height: "56px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: { xs: "100%", sm: "120px" }, // Responsive width
                }}
              >
                Search
              </Button>
              <Button
                onClick={handleClear}
                sx={{
                  backgroundColor: "gray",
                  color: "white",
                  height: "56px",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: { xs: "100%", sm: "120px" }, // Responsive width
                }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {/* Table */}
          <Grid style={{ marginTop: "3%" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: "100%",
                  overflowX: "auto",
                  borderRadius: "12px 12px 0px 0px",
                }}
              >
                <Table>
                  <TableHead
                    style={{ backgroundColor: "#027BFE", color: "white" }}
                  >
                    <TableRow>
                      <TableCell style={{ color: "white" }}>Sr. No.</TableCell>
                      <TableCell style={{ color: "white" }}>
                        Transaction ID
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Receiver ID
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Receiver Name
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        User Type
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Date & Time
                      </TableCell>
                      <TableCell style={{ color: "white" }}>
                        Opening Balance
                      </TableCell>
                      <TableCell style={{ color: "white" }}>Amount</TableCell>
                      <TableCell style={{ color: "white" }}>
                        Closing Balance
                      </TableCell>
                      <TableCell style={{ color: "white" }}>Invoice</TableCell>{" "}
                      {/* New Column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.length > 0 ? (
                      transactions.map((txn, index) => (
                        <TableRow
                          key={txn.tId}
                          className={
                            index % 2 === 0 ? classes.oddRow : classes.evenRow
                          }
                        >
                          <TableCell>
                            {currentPage * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell>{txn.tId}</TableCell>
                          <TableCell>{txn.receiverId}</TableCell>
                          <TableCell>{txn.receiverName}</TableCell>
                          <TableCell>{txn.utype}</TableCell>
                          <TableCell>
                            {dayjs(txn.dt).format("DD/MM/YYYY hh:mm A")}
                          </TableCell>
                          <TableCell>
                            {txn.userWltOpeningBlc
                              ? txn.userWltOpeningBlc.toFixed(2)
                              : "N/A"}
                          </TableCell>
                          <TableCell>{txn.amount}</TableCell>
                          <TableCell>
                            {txn.userWltClosingBlc
                              ? txn.userWltClosingBlc.toFixed(2)
                              : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            <DownloadIcon
                              onClick={() => handleDownloadInvoice(txn)}
                              style={{ cursor: "pointer" }}
                            />
                          </TableCell>
                          <Grid
                            style={{
                              position: "absolute",
                              left: "-9999px",
                              top: "0px",
                            }}
                          >
                            <Grid id={`receipt-${txn.tId}`}>
                              <ReverseMoneyInvoice data={txn} />
                            </Grid>
                          </Grid>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No matching records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TableFooter style={{ display: "flex", justifyContent: "end" }}>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={totalRecords} // Total number of records fetched
                      rowsPerPage={rowsPerPage}
                      page={currentPage} // Current page index
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      nextIconButtonProps={{
                        disabled:
                          (currentPage + 1) * rowsPerPage >= totalRecords ||
                          statusCodeValue === 204,
                      }}
                      backIconButtonProps={{
                        disabled: currentPage === 0,
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </TableContainer>
            )}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default ReverseMoneyReports;
