import React, { useState } from "react";
import { Box, TextField, Button, Grid } from "@mui/material";
import axios from "axios";

export default function PostpaidRecharge() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [operatorDetails, setOperatorDetails] = useState(null); // To store operator details
  const [postpaidInfo, setPostpaidInfo] = useState(null); // To store postpaid info after second API call

  const handlePayment = async () => {
    if (mobileNumber.length !== 10) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // First API call to get operator and circle details
      const operatorResponse = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/OperatorCheck?tel=${mobileNumber}`,
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDIxNTU5OTI3IiwiaWF0IjoxNzMxNzYxODA1LCJleHAiOjE3MzE4MjE4MDV9.reFOak4vrzKkoaovTo7mWXETFPAETvO2DF53EO1R9YssdaLhlkqmmyBToK9FJ2rJwKdQwJssljf3CaciVVT9bw",
          },
        }
      );

      const operatorData = operatorResponse.data;

      if (operatorResponse.status === 200) {
        setOperatorDetails(operatorData.records); // Store operator details
        console.log("API Response:", operatorData);

        // Second API call to get postpaid info using operator and mobile number
        const postpaidResponse = await axios.get(
          `https://api.ipaisa.co.in/myapp/auth/getPostpaidInfo?tel=${mobileNumber}&operator=${operatorData.records.Operator}`, // Using quotes around operator
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5OTc3MTA0MTgwIiwiaWF0IjoxNzMwNzg2NDA1LCJleHAiOjE3MzE2NTA0MDV9.pXJwIIDK-sQTiUAm-Vd1w0b5ymydtVUwmxqzzhO3-GT_JL3rkUw8EZ0Q9vzDOfGutDpOHmSnGmNWfGRT1SFcGw",
            },
          }
        );

        const postpaidData = postpaidResponse.data;

        if (postpaidResponse.status === 200) {
          setPostpaidInfo(postpaidData); // Store postpaid info
          console.log("Postpaid Info:", postpaidData);
        } else {
          setError("Failed to fetch postpaid info");
          console.log("Postpaid API Error:", postpaidData);
        }
      } else {
        setError("Failed to fetch operator details");
        console.log("Operator API Error:", operatorData);
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePayBill = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Making the API call for recharge
      const rechargeResponse = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/billPay/recharge",
        {
          mn: mobileNumber,
          amt: amount || "10", // Default amount if not provided
          providerName: operatorDetails?.Operator || "Unknown Provider", // Use operatorDetails from state
          type: "postpaid recharge",
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDIxNTU5OTI3IiwiaWF0IjoxNzMxNzYxODA1LCJleHAiOjE3MzE4MjE4MDV9.reFOak4vrzKkoaovTo7mWXETFPAETvO2DF53EO1R9YssdaLhlkqmmyBToK9FJ2rJwKdQwJssljf3CaciVVT9bw",
          },
        }
      );

      // Check if the recharge was successful
      if (rechargeResponse.status === 200) {
        console.log("Recharge successful:", rechargeResponse.data);
        alert("Recharge successful!"); // Provide feedback to the user
      } else {
        setError("Recharge failed. Please try again.");
        console.error("Recharge failed:", rechargeResponse.data);
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error during recharge:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger API call when user leaves the mobile number input (onBlur event)
  const handleMobileBlur = () => {
    if (mobileNumber.length === 10) {
      handlePayment();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            label="Mobile Number"
            variant="outlined"
            fullWidth
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            onBlur={handleMobileBlur} // Call handlePayment when the field loses focus
            error={error ? true : false}
            helperText={error && error.includes("mobile") ? error : ""}
          />
        </Grid>
        {/* <Grid item md={6} xs={12}>
          <TextField
            label="Amount"
            variant="outlined"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid> */}
      </Grid>

      {error && !error.includes("mobile") && (
        <Box sx={{ mt: 2, color: "red" }}>
          <strong>{error}</strong>
        </Box>
      )}

      {operatorDetails && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                label="Operator"
                variant="outlined"
                fullWidth
                value={operatorDetails.Operator || ""}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Circle"
                variant="outlined"
                fullWidth
                value={operatorDetails.circle || ""}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {postpaidInfo && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                label="Postpaid Info"
                variant="outlined"
                fullWidth
                value={postpaidInfo.someField || "N/A"} // Replace with actual data fields from postpaid response
                disabled
              />
            </Grid>
            {/* Add more fields based on the postpaidInfo response */}
          </Grid>
        </Box>
      )}
      <Box sx={{ mt: 3 }}>
        <Button
          onClick={handlePayBill}
          style={{
            color: "white",
            backgroundColor: "#027BFE",
            textTransform: "none",
            fontSize: "16px",
          }}
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Pay Now"}
        </Button>
      </Box>
    </Box>
  );
}
