import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from "@mui/material";
import axios from "axios";

const partners = [
  "Channel Partner",
  "Super Distributor",
  "Master Distributor",
  "Area Distributor",
  "Retailer",
];

const SendSms = () => {
  const [selectedPartner, setSelectedPartner] = useState("");
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [smsDialogOpen, setSmsDialogOpen] = useState(false);

  const sendMessage = () => {
    axios
      .post("https://api.ipaisa.co.in/myapp/send-sms", { userId, message })
      .then((response) => {
        alert("Message sent successfully!");
        setUserId("");
        setMessage("");
        setSmsDialogOpen(false);
      })
      .catch((error) => {
        console.error("There was an error sending the message!", error);
      });
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "13%", padding: "0px 100px 0px 100px" }}
    >
      <Box
        mt={4}
        mb={2}
        p={4}
        boxShadow={2}
        borderRadius={4}
        bgcolor="background.paper"
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Button
              style={{
                backgroundColor: "#027BFE",
                color: "white",
                textTransform: "none",
                width: "100%",
                height: "130%",
                borderRadius: "10px",
              }}
              onClick={() => setSmsDialogOpen(true)}
              fullWidth
            >
              Send SMS
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              style={{
                backgroundColor: "#9C27B0",
                color: "white",
                textTransform: "none",
                width: "100%",
                height: "130%",
                borderRadius: "10px",
              }}
              onClick={() => setOpen(true)}
              fullWidth
            >
              Send Message by ID
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={smsDialogOpen} onClose={() => setSmsDialogOpen(false)}>
        <DialogTitle style={{ fontWeight: "bold" }}>Send SMS</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={selectedPartner}
            onChange={(e) => setSelectedPartner(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Partner Level
            </MenuItem>
            {partners.map((partner, index) => (
              <MenuItem key={index} value={partner}>
                {partner}
              </MenuItem>
            ))}
          </Select>
          <TextField
            fullWidth
            multiline
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
            style={{ marginTop: "10px" }}
            inputProps={{
              style: {
                minHeight: "60px",
                height: "auto",
              },
            }}
          />
        </DialogContent>
        <DialogActions style={{ padding: "0px 22px 20px 22px" }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button
              onClick={() => setSmsDialogOpen(false)}
              style={{
                backgroundColor: "#E3E4E6",
                color: "#808080",
                textTransform: "none",
                width: "48%",
                borderRadius: "9px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={sendMessage}
              style={{
                backgroundColor: "#027BFE",
                color: "white",
                fontWeight: "bold",
                textTransform: "none",
                width: "48%",
                borderRadius: "9px",
              }}
            >
              Send SMS
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ fontWeight: "bold" }}>
          Send Message by ID
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            placeholder="Enter user ID"
            style={{ marginTop: "10px" }}
          />
          <TextField
            fullWidth
            multiline
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
            style={{ marginTop: "10px" }}
            inputProps={{
              style: {
                minHeight: "60px",
                height: "auto",
              },
            }}
          />
        </DialogContent>
        <DialogActions style={{ padding: "0px 22px 20px 22px" }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button
              onClick={() => setOpen(false)}
              style={{
                backgroundColor: "#E3E4E6",
                color: "#808080",
                textTransform: "none",
                width: "48%",
                borderRadius: "9px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={sendMessage}
              style={{
                backgroundColor: "#027BFE",
                color: "white",
                fontWeight: "bold",
                textTransform: "none",
                width: "48%",
                borderRadius: "9px",
              }}
            >
              Send Message
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SendSms;
