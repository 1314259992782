import React, { useState, useRef } from "react";
import {
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
  Grid,
  Container,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ForgotPin = ({ open, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [newPassword, setNewPassword] = useState(["", "", "", ""]);
  const [confirmPassword, setConfirmPassword] = useState(["", "", "", ""]);
  const [passwordError, setPasswordError] = useState("");
  const newPassRefs = useRef([]);
  const confirmPassRefs = useRef([]);

  const handleOpenModal = async () => {
    setLoading(true);
    setIsMobileValid(false);
    setOtpSent(false);

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      toast.error("Please enter a valid 10-digit mobile number.");
      setLoading(false);
      return;
    }

    try {
      const validateResponse = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/validatemobilenumber",
        { mobileno: phoneNumber }
      );

      if (validateResponse.data.mobileValidate) {
        setIsMobileValid(true);
        const otpResponse = await axios.post(
          "https://api.ipaisa.co.in/myapp/auth/otpmsg",
          { mobileNumber: phoneNumber }
        );

        if (otpResponse.status === 200) {
          toast.success("OTP has been sent to your WhatsApp Number");
          setOtpSent(true);
        }
      } else {
        toast.error("Mobile number is already registered.");
      }
    } catch (error) {
      console.error("Error validating mobile number:", error);
      toast.error("Error validating mobile number. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    if (!otp) {
      toast.error("Please enter the OTP.");
      return;
    }

    setLoading(true);
    try {
      const otpValidationResponse = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/validateotp",
        { mobileno: phoneNumber, otp }
      );

      if (otpValidationResponse.data.status === "success") {
        toast.success("OTP verified successfully.");
        setResetPass(true);
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSetPassword = async () => {
    const mpin = newPassword.join("");
    const confirmMpin = confirmPassword.join("");

    if (mpin.length < 4) {
      setPasswordError("IPIN must be at least 4 digits long.");
      return;
    }

    if (mpin !== confirmMpin) {
      setPasswordError("IPIN must match");
      return;
    }

    setPasswordError("");
    const jwtToken = localStorage.getItem("jwtToken");

    try {
      const response = await axios.put(
        "https://api.ipaisa.co.in/myapp/auth/updateMpin",
        { mpin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (response.data && response.data.body) {
        toast.success(response.data.body.message, {
          position: "top-right",
          autoClose: 3000,
        });

        setTimeout(() => {
          onClose(); // Close the modal
        }, 3000);
      }
    } catch (error) {
      setPasswordError("Failed to set IPIN. Please try again.");
      console.error("API Error:", error);
      toast.error("Failed to set IPIN. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleNewPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...newPassword];
      updatedPassword[index] = value;
      setNewPassword(updatedPassword);
      if (index < 3) {
        newPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleConfirmPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...confirmPassword];
      updatedPassword[index] = value;
      setConfirmPassword(updatedPassword);
      if (index < 3) {
        confirmPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, passwordType) => {
    const updatedPassword =
      passwordType === "new" ? [...newPassword] : [...confirmPassword];

    if (updatedPassword[index] !== "") {
      updatedPassword[index] = "";
      if (passwordType === "new") {
        setNewPassword(updatedPassword);
        newPassRefs.current[index].focus();
      } else {
        setConfirmPassword(updatedPassword);
        confirmPassRefs.current[index].focus();
      }
    } else if (index > 0) {
      if (passwordType === "new") {
        newPassRefs.current[index - 1].focus();
      } else {
        confirmPassRefs.current[index - 1].focus();
      }
    }
  };

  const renderPasswordFields = (
    password,
    handleChange,
    handleBackspace,
    refs
  ) => (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      {password.map((value, index) => (
        <Grid item xs={3} key={index}>
          <TextField
            inputRef={(el) => (refs.current[index] = el)}
            type="password"
            variant="outlined"
            value={value}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                handleBackspace(
                  index,
                  password === newPassword ? "new" : "confirm"
                );
              }
            }}
            fullWidth
            required
            InputProps={{
              style: { textAlign: "center" }, // Center text input
            }}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Forgot Password</DialogTitle>
      <DialogContent>
        {!resetPass ? (
          <>
            <Typography
              variant="body1"
              style={{ marginBottom: "1.5rem", color: "#666" }}
            >
              Enter your phone number below, and we’ll send you a link to reset
              your password.
            </Typography>
            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              margin="normal"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ marginBottom: "1.5rem" }}
            />
            {otpSent && (
              <TextField
                fullWidth
                label="Enter OTP"
                variant="outlined"
                margin="normal"
                required
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                style={{ marginBottom: "1.5rem" }}
              />
            )}
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              {loading ? (
                <CircularProgress size={24} style={{ marginLeft: "16px" }} />
              ) : (
                <>
                  {!otpSent ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                    >
                      Send
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOtpSubmit}
                    >
                      Verify OTP
                    </Button>
                  )}
                </>
              )}
            </DialogActions>
          </>
        ) : (
          <>
            <Container>
              <Typography variant="h5" align="center" gutterBottom>
                Set IPIN
              </Typography>
              <Box mb={2} display="flex" justifyContent="center">
                {renderPasswordFields(
                  newPassword,
                  handleNewPasswordChange,
                  handleBackspace,
                  newPassRefs
                )}
              </Box>
              <Box mb={2} display="flex" justifyContent="center">
                {renderPasswordFields(
                  confirmPassword,
                  handleConfirmPasswordChange,
                  handleBackspace,
                  confirmPassRefs
                )}
              </Box>
              {passwordError && (
                <Typography variant="body2" color="error" align="center">
                  {passwordError}
                </Typography>
              )}
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSetPassword}
                >
                  Set Password
                </Button>
              </DialogActions>
            </Container>
          </>
        )}
      </DialogContent>
      <ToastContainer />
    </Dialog>
  );
};

export default ForgotPin;
