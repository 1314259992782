import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import WalletBalanceComponent from "./WalletBalanceComponent";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import MoneyAddedSucc from "./MoneyAddedSucc";
import MoneyFailedtoAdd from "./MoneyFailedtoAdd";
import axios from "axios";
import IpinModal from "../IpaisaMpin/IpinModal";
import SetPasswordModal from "../IpaisaMpin/SetIPMpin";
import { values } from "pdf-lib";

const AddMoneyNew = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [txnid, setTxnid] = useState("");
  const [moneyaddedpop, setMoneyaddedpop] = useState(false);
  const [moneyfailedtoadd, setMoneyfailedtoadd] = useState(false);
  const [openIpinModal, setOpenIpinModal] = useState(false);
  const [formValues, setFormValues] = useState({ amount: "", remark: "" });
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);

  const handleOpenModal = () => {
    const isTxnMpinSet = localStorage.getItem("isTxnMpinSet");

    if (isTxnMpinSet === "False") {
      toast.error("Please set IPin first.");
      setTimeout(() => {
        setOpenSetPasswordModal(true);
      }, 3000);
    } else {
      setOpenIpinModal(true);
      activePg();
    }
  };

  const handleClose = () => {
    setMoneyaddedpop(false);
    setMoneyfailedtoadd(false);
    setOpenIpinModal(false);
    setOpenSetPasswordModal(false);
  };

  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();
    // activePg();
  }, []);
  const createOrder = async (amount, remark) => {
    try {
      const token = localStorage.getItem("jwtToken");

      // Ensure amount is an integer
      const integerAmount = Math.round(amount);

      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/razorpay/create-order",
        { amount: integerAmount, remark }, // Include remark in the request payload
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Store the order data in response.data and return it
      return response.data;
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("Failed to create order.");
      return null;
    }
  };

  // Payment handling function
  const handlePayment = async (orderData) => {
    try {
      const { id: orderId, amount, currency, key } = orderData;

      // Check if Razorpay is loaded
      if (typeof window.Razorpay === "undefined") {
        toast(
          "Razorpay SDK not loaded. Please check your internet connection."
        );
        return;
      }

      const options = {
        key: "rzp_live_9lZyU2RZhKmttY",
        amount: amount,
        currency: currency,
        name: "ipaisa",
        description: "Transaction",
        order_id: orderId,
        image:
          "https://ipaisa.co.in/static/media/iPaisaLogo.3682d2408e2c0eb923ed.jpg",
        handler: async function (response) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };
          console.log("Order ID:", response.razorpay_order_id);
          console.log("Payment ID:", response.razorpay_payment_id);
          console.log("Signature:", response.razorpay_signature);
          toast("Order ID: " + response.razorpay_order_id);
          toast("Payment ID: " + response.razorpay_payment_id);

          try {
            const verifyResponse = await axios.post(
              "https://api.ipaisa.co.in/myapp/auth/verify_razorpay_payment",
              paymentData
            );
            if (verifyResponse.status === 200) {
              toast.success("Payment successful and verified!");
              setMoneyaddedpop(true);
              setMoneyfailedtoadd(false);
            } else {
              toast.error("Payment verification failed");
              setMoneyfailedtoadd(true);
              setMoneyaddedpop(false);
            }
          } catch (error) {
            console.error("Payment verification error:", error);
            toast.error("Payment verification failed");
          }
        },
        prefill: {
          name: localStorage.getItem("username"),
          // email: localStorage.getItem(''),
          // contact: "8421570262",
        },
        notes: {
          address: "Corporate Office",
        },
        theme: {
          color: "#03DBFC",
        },
      };

      // Open Razorpay checkout window
      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      // Payment failure handler
      rzp1.on("payment.failed", function (response) {
        console.error("Payment failed:", response.error);
        toast.error("Payment failed. Please try again.");
        setMoneyfailedtoadd(true);
        setMoneyaddedpop(false);
      });
    } catch (error) {
      console.error("Error in payment handler:", error);
      toast.error("Something went wrong. Please try again.");
      setMoneyfailedtoadd(true);
      setMoneyaddedpop(false);
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Amount must be a number")
      .positive("Amount must be positive")
      .required("Amount is required"),
    remark: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
      remark: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setFormValues(values);
      handleOpenModal();
    },
  });
  const [providerName, setProviderName] = useState("");
  const activePg = async (values) => {
    try {
      const token = localStorage.getItem("jwtToken");

      // Fetch active payment gateway provider
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/get-active-pg-provider",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if the response is successful
      if (response.status === 200) {
        const { providerName, status } = response.data.data;
        console.log(providerName, "HEEEEEEEE");
        setProviderName(providerName);
        // Handle different providers
        if (status === "ACTIVE") {
          if (providerName === "RAZORPAY") {
            // await handlePaymentClick(values); // Call Razorpay payment flow
          } else if (providerName === "EASEBUZZ") {
            // await initiatePayment(values); // Call Easebuzz payment flow
          } else {
            toast.error(`Payment gateway "${providerName}" is not supported.`);
          }
        } else {
          toast.error(`Payment gateway "${providerName}" is not active.`);
        }
      } else {
        toast.error("No active payment gateway provider found.");
      }
    } catch (error) {
      console.error("Error fetching active payment gateway:", error);
      toast.error(
        "An error occurred while fetching the payment gateway provider."
      );
    }
  };

  const initiatePayment = async (values) => {
    try {
      const paymentData = {
        txnid: "iPaisa" + new Date().getTime(),
        amount: values.amount,
        productinfo: "Add Money",
        firstname: "Edsom",
        phone: "8378054080",
        email: "edsomfintech@gmail.com",
        surl: "https://ipaisa.co.in/signin",
        furl: "https://ipaisa.co.in/signin",
      };

      setTxnid(paymentData.txnid);

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 1) {
        const accessKey = response.data.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
        const newWindow = window.open(paymentUrl, "_blank");

        if (newWindow) {
          newWindow.focus();

          const checkWindowLocation = setInterval(() => {
            try {
              if (
                newWindow.location.href === "https://ipaisa.co.in/signin"
              ) {
                clearInterval(checkWindowLocation);
                newWindow.close();
                handlePaymentStatus(paymentData.txnid);
              }
            } catch (e) {
              console.error("Cross-origin error:", e);
            }
          }, 1000);
        }
      } else {
        toast.error("Failed to initiate payment");
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handleConfirmPayment = async () => {
    setTimeout(async () => {
      setOpenIpinModal(false);
      console.log(providerName + " active payment gateway");

      if (providerName === "EASEBUZZ") {
        // Ensure formValues contain the necessary data (like amount)
        await initiatePayment(formValues);
        console.log("EASEBUZZ payment initiated with values:", formValues);
      } else if (providerName === "RAZORPAY") {
        const { amount, remark } = formValues;

        try {
          const orderData = await createOrder(amount, remark); // Include remark in the API call
          if (orderData) {
            await handlePayment(orderData);
            console.log(
              "RAZORPAY payment initiated with order data:",
              orderData
            );
          } else {
            console.error("Order creation failed for RAZORPAY.");
          }
        } catch (error) {
          console.error("Error during RAZORPAY payment process:", error);
        }
      } else {
        console.error("Unsupported payment provider:", providerName);
      }
    }, 1000);
  };

  const handlePaymentStatus = async (txnid) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/transaction-api-v2",
        { txnid },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const { status, message } = response.data;

        if (status.toLowerCase() === "success") {
          toast.success("Funds Added Successfully");
          setMoneyaddedpop(true);
          setMoneyfailedtoadd(false);
          formik.resetForm();
        } else {
          toast.error("Payment failed: " + (message || "Unknown error"));
          setMoneyfailedtoadd(true);
          setMoneyaddedpop(false);
        }
        await fetchWalletBalance();
      } else {
        toast.error(
          "Payment failed: " + (response.data.message || "Unknown error")
        );
        setMoneyfailedtoadd(true);
        setMoneyaddedpop(false);
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
      setMoneyfailedtoadd(true);
      setMoneyaddedpop(false);
      toast.error("An error occurred while verifying the payment.");
    }
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    let validValue = value.replace(/[^0-9]/g, "");
    if (validValue.startsWith("0") && validValue.length > 1) {
      validValue = validValue.replace(/^0+/, "");
    }
    if (validValue === "0") {
      validValue = "";
    }
    formik.setFieldValue(name, validValue);
  };

  const handleCancel = () => {
    formik.resetForm(); // Clear form on Cancel
  };

  const inputHeight = "50px";

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1%",
            justifyContent: "start",
          }}
        >
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "13px" }}
          >
            Balance
          </Typography>
          <PartnerArrowIcon />
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "13px" }}
          >
            Add Money
          </Typography>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#343C6A",
              }}
            >
              Add Money
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "3%" }}>
        <WalletBalanceComponent walletBalance={walletBalance} />
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          <Paper
            style={{ boxShadow: "none", padding: "2rem", borderRadius: "8px" }}
          >
            <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h7">Amount</Typography>
                  <TextField
                    name="amount"
                    type="text"
                    value={formik.values.amount}
                    onChange={handleAmountChange}
                    fullWidth
                    required
                    InputLabelProps={{ style: { height: "2.5em" } }}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h7">Remark</Typography>
                  <TextField
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    fullWidth
                    required
                    InputLabelProps={{ style: { height: "2.5em" } }}
                    error={
                      formik.touched.remark && Boolean(formik.errors.remark)
                    }
                    helperText={formik.touched.remark && formik.errors.remark}
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: "6.5%" }}>
                  <Box display="flex" justifyContent="end" gap={1}>
                    <Button
                      sx={{
                        border: "1.5px solid #FF0000",
                        color: "#FF0000",
                        textTransform: "none",
                        width: "18%",
                        fontSize: "16px",
                        height: inputHeight,
                        borderRadius: "8px",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleOpenModal}
                      disabled={!(formik.dirty && formik.isValid)} // Disable if the form is invalid
                      sx={{
                        backgroundColor: "#027BFE",
                        color: "white",
                        textTransform: "none",
                        width: "18%",
                        fontSize: "14px",
                        borderRadius: "8px",
                        height: inputHeight,
                      }}
                    >
                      Add Money
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {moneyaddedpop && (
        <MoneyAddedSucc open={moneyaddedpop} handleClose={handleClose} />
      )}
      {moneyfailedtoadd && (
        <MoneyFailedtoAdd open={moneyfailedtoadd} handleClose={handleClose} />
      )}
      {localStorage.getItem("isTxnMpinSet") === "False" ? (
        <SetPasswordModal
          open={openSetPasswordModal}
          onClose={() => setOpenSetPasswordModal(false)}
          onMpinValid={handleConfirmPayment}
        />
      ) : (
        <IpinModal
          open={openIpinModal}
          onClose={handleClose}
          onMpinValid={handleConfirmPayment}
        />
      )}
    </Container>
  );
};

export default AddMoneyNew;
