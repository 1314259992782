import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import to detect the current route
import { Button, Box, Container, Paper, Typography, Grid } from "@mui/material";
import DrawIcon from "@mui/icons-material/Draw";
import axios from "axios";

const Agreement = () => {
  const [counter, setCounter] = useState();
  const location = useLocation(); // Hook to get current URL
  const [parameter1, setParam1] = useState("");
  const [parameter2, setParam2] = useState("");
  const [parameter3, setParam3] = useState("");

  // Detect API payload upon redirection to the specific URL

  useEffect(() => {
    if (location.pathname === "/Agreement") {
      const fetchPayload = async () => {
        try {
          const token = localStorage.getItem("jwtToken");
          if (!token) {
            console.error("Authentication token missing");
            return;
          }

          // First API call
          const response = await axios.get(
            "https://test.ipaisa.co.in/Agreement",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              validateStatus: (status) => status === 200 || status === 304, // Handle both success and not modified statuses
            }
          );

          console.log("API Response:", response);

          // Check for status code 304
          if (response.status === 304) {
            try {
              const downloadResponse = await axios.get(
                "https://api.ipaisa.co.in/myapp/auth/downloadPdf",
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              console.log("PDF Download Response:", downloadResponse.data);
              // Handle success case, e.g., download the PDF
              const blob = new Blob([downloadResponse.data], {
                type: "application/pdf",
              });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "agreement.pdf";
              link.click();
            } catch (error) {
              console.error("Error calling PDF download API:", error);
            }
          }
        } catch (error) {
          console.error("Error fetching API payload:", error);
        }
      };

      fetchPayload();
    }
  }, [location]);

  const handleSignClick = async () => {
    const apiEndpoint = "https://api.ipaisa.co.in/myapp/auth/get-sign-doc";

    try {
      const token = localStorage.getItem("jwtToken");
      if (!token) {
        alert("Authentication token is missing!");
        return;
      }

      const pdfFile = await fetch(
        `${process.env.PUBLIC_URL}/AgreementCP.pdf`
      ).then((res) => res.blob());

      const formData = new FormData();
      formData.append("file", pdfFile, "AgreementCP.pdf");

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (
        response.data?.parameter1 &&
        response.data?.parameter2 &&
        response.data?.parameter3
      ) {
        const { parameter1, parameter2, parameter3 } = response.data;

        setParam1(parameter1);
        setParam2(parameter2);
        setParam3(parameter3);

        const Test_URL =
          "https://demosignergateway.emsigner.com/eMsecure/V3_0/Index";

        const form = document.createElement("form");
        form.method = "POST";
        form.action = Test_URL;

        form.appendChild(createHiddenInput("Parameter1", parameter1));
        form.appendChild(createHiddenInput("Parameter2", parameter2));
        form.appendChild(createHiddenInput("Parameter3", parameter3));

        document.body.appendChild(form);

        form.submit();
        console.log("Form submitted to:", Test_URL);
      } else {
        console.error("Response lacks expected parameters:", response.data);
        alert("Failed to sign the agreement: Missing parameters in response.");
      }
    } catch (error) {
      console.error("Error signing the agreement:", error);
      alert("An error occurred while signing the agreement.");
    }
  };

  const createHiddenInput = (name, value) => {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = name;
    input.value = value;
    return input;
  };

  return (
    <Container
      sx={{
        padding: { xs: "0.8rem", md: "0rem" },
        marginTop: "calc(3% + 56px)",
        marginBottom: "2%",
      }}
    >
      <Paper
        sx={{
          padding: { xs: "0.8rem", md: "2rem" },
          borderRadius: "12px",
          boxShadow: "none",
          marginTop: "3%",
        }}
      >
        <Typography
          style={{ fontSize: "22px", fontWeight: "bold", marginBottom: "2%" }}
        >
          Agreement Document
        </Typography>
        <Box
          component="iframe"
          src={`${process.env.PUBLIC_URL}/AgreementCP.pdf`}
          title="Agreement PDF"
          sx={{
            width: "100%",
            height: { xs: 300, sm: 500 },
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        ></Box>

        <Grid style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="large"
            onClick={handleSignClick}
            sx={{
              marginTop: "2%",
              color: "white",
              backgroundColor: "green",
              textTransform: "none",
              width: "16%",
              gap: "4%",
              fontSize: "16px",
            }}
          >
            Sign
            <DrawIcon sx={{ fontSize: "18px" }} />
          </Button>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Agreement;
