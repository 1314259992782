import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Container,
  TablePagination,
  CircularProgress,
  IconButton,
  Box,
  InputBase,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  evenRow: {
    backgroundColor: "#C1E0FB",
  },
  oddRow: {
    backgroundColor: "#FFFFFF",
  },
  PayInText: {
    color: "#027BFE",
  },
}));
const CardandBarChart = () => {
  const [data, setData] = useState([]);
  const [totalPayout, setTotalPayout] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [todayTransacted, setTodayTransacted] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [failedAmount, setFailedAmount] = useState(0);
  const [refundAmount, setRefundAmount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTransactionId, setSearchTransactionId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hasMore, setHasMore] = useState(true); // To track if there are more pages
  const [noContent, setNoContent] = useState(false); // New state for no transactions
  const classes = useStyles();
  const [isRefreshing, setIsRefreshing] = useState(false); // Track refresh status
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleSearch = () => {
    setPage(0); // Reset to the first page on new search

    // If searchQuery is empty or only whitespace, call the API with page=0 and rowsPerPage=10
    if (!searchQuery.trim()) {
      fetchCompleteData(0, fromDate, toDate, "", 10); // Call API with no search query
    } else {
      fetchCompleteData(0, fromDate, toDate, searchQuery, rowsPerPage); // Call API with search query
    }
  };

  // Handle search input change (no API call here)
  const handleSearchInput = (event) => {
    const updatedSearchQuery = event.target.value; // Get the updated search query value
    setSearchQuery(updatedSearchQuery); // Update search query state

    // Check if the search query length becomes 0 (i.e., the input is cleared)
    if (updatedSearchQuery.length === 0) {
      // Fetch data with default parameters when search query is cleared
      fetchCompleteData(0, "", "", "", rowsPerPage);
    }
  };

  // Function to fetch completed data from the API
  // Function to fetch completed data from the API
  const fetchCompleteData = async (
    page,
    fromDate,
    toDate,
    searchQuery,
    rowsPerPage
  ) => {
    setLoading(true); // Set loading to true before making the API call

    const token = localStorage.getItem("jwtToken");

    // Build the URL parameters conditionally
    let url = "https://api.ipaisa.co.in/myapp/admin/allPayout?";

    // Add search query if available
    if (searchQuery.trim()) {
      url += `searchBy=${searchQuery}&`;
    }

    // Add fromDate if available
    if (fromDate) {
      url += `fromDate=${fromDate}&`;
    }

    // Add toDate if available
    if (toDate) {
      url += `toDate=${toDate}&`;
    }

    // Add pagination parameters (page and rowsPerPage)
    url += `page=${page}&rowsPerPage=${rowsPerPage}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (
        response.data.statusCodeValue === 204 ||
        response.data.body.message === "NO_CONTENT"
      ) {
        // Handle the case when no content is returned
        console.log("No content available");
        setFilteredData([]); // Clear the data state if no content
        // setStatusCodeValue(response.data.statusCodeValue);
        setTotalTransactions(0); // Reset totals to 0
        setPendingAmount(0);
        setFailedAmount(0);
        setRefundAmount(0);
      } else {
        const body = response.data.body;

        // Set the transaction data
        setFilteredData(body.Transactions || []);
        // setTotalPages(body.Total_Pages || 0);

        // Set the amounts
        setTotalTransactions(body.Total_Records);
        setTotalPayout(body.Successful_Transactions_Amount || 0); // Total of successful transactions
        setPendingAmount(body.Pending_Transactions_Amount || 0); // Pending amount
        setFailedAmount(body.Failed_Transactions_Amount || 0); // Failed transactions
        setRefundAmount(body.Refund_Transactions_Amount || 0); // Refund amount

        // Prepare 7-day data for the chart if applicable
        const last7DaysData = getLast7DaysData(body.Transactions || []);
        setData(last7DaysData);
      }
    } catch (error) {
      console.error("Error fetching completed transactions:", error);
      setFilteredData([]); // Clear data in case of error
      setTotalTransactions(0); // Reset totals to 0
      setPendingAmount(0);
      setFailedAmount(0);
      setRefundAmount(0);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  useEffect(() => {
    fetchCompleteData(page, fromDate, toDate, searchQuery, rowsPerPage); // Fetch data on component mount or when page/filters change
  }, [page]); // Run when these dependencies change

  const handleClear = () => {
    setFromDate("");
    setSearchQuery("");
    setToDate("");
    setPage(0); // Reset to first page
    fetchCompleteData(0, "", "", "", rowsPerPage); // Fetch data with default params when cleared
  };

  const handleRefresh = () => {
    setIsRefreshing(true); // Start spinner
    // fetchTotalPayout(fromDate, toDate, page, rowsPerPage);
  };

  const getLast7DaysData = (transactions) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const last7Days = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const dayName = days[date.getDay()];
      const dayDate = date.toISOString().split("T")[0];

      const payout = transactions
        .filter((txn) => txn.timestamp.split("T")[0] === dayDate)
        .reduce((sum, txn) => sum + parseFloat(txn.txnValue || 0), 0);

      last7Days.push({ day: dayName, payout });
    }
    return last7Days;
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page && hasMore) {
      // If the user is moving to the next page and there are more transactions, call the API
      // fetchTotalPayout(fromDate, toDate, newPage, rowsPerPage);
    } else if (newPage < page && page > 0) {
      // If the user is moving to the previous page, call the API for the previous page
      // fetchTotalPayout(fromDate, toDate, newPage, rowsPerPage);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    // fetchTotalPayout(fromDate, toDate, 0, newRowsPerPage);
  };

  useEffect(() => {
    // fetchTotalPayout();
  }, []);

  return (
    <Container style={{ padding: "1rem", marginTop: "calc(4% + 58px)" }}>
      {/* <Grid style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontSize: '18px', color: 'grey' }}>Refresh</Typography>
        <IconButton onClick={handleRefresh} disabled={isRefreshing}>
          {isRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
        </IconButton>
      </Grid> */}

      <Paper
        style={{
          padding: "2rem",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          spacing={2} // Adds spacing between items
          alignItems="center" // Aligns content vertically
          justifyContent="space-between" // Adjusts horizontal spacing
          style={{ flexWrap: "wrap" }} // Ensures content wraps on small screens
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            sx={{
              border: "1px solid #D9D9D9",
              borderRadius: "18px",
              "&:hover": { boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" },
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Total Transactions
            </Typography>
            <Typography style={{ textAlign: "center" }}>
              {totalTransactions}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Successful Amount
            </Typography>
            <Typography style={{ textAlign: "center", color: "green" }}>
              ₹{totalPayout}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Pending Amount
            </Typography>
            <Typography style={{ textAlign: "center", color: "orange" }}>
              ₹{pendingAmount}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Failed Amount
            </Typography>
            <Typography style={{ textAlign: "center", color: "red" }}>
              ₹{failedAmount}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Refund Amount
            </Typography>
            <Typography style={{ textAlign: "center", color: "darkgoldenrod" }}>
              ₹{refundAmount}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0.5%",
          marginTop: "2%",
        }}
      >
        <Grid item xs={12} md={4}>
          <Paper
            style={{
              boxShadow: "none",
              padding: "1rem",
              borderRadius: "10px",
              marginBottom: "4%",
            }}
          >
            <Typography variant="h6" gutterBottom>
              7-Day Payout Summary
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: "1rem" }}
            >
              Total Payout: ₹{totalPayout}
            </Typography>
          </Paper>

          <Paper
            style={{
              boxShadow: "none",
              padding: "1rem",
              borderRadius: "10px",
              marginBottom: "4%",
            }}
          >
            <TextField
              label="From Date"
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginBottom: "0.5rem" }}
            />
            <TextField
              label="To Date"
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
              style={{ marginBottom: "1rem" }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
              style={{ marginRight: "0.5rem" }}
            >
              Search
            </Button>
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={7}>
          <Paper
            style={{
              boxShadow: "none",
              padding: "1rem",
              borderRadius: "10px",
              marginBottom: "4%",
            }}
          >
            <Typography variant="h6" gutterBottom>
              7-Day Payout Chart
            </Typography>
            {data.length > 0 ? (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="payout" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <Typography style={{ textAlign: "center", marginTop: "1rem" }}>
                There is no transaction available on selected date
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginTop: "2%" }}
      >
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Recent Transactions</Typography>
          {/* <IconButton onClick={handleRefresh} disabled={isRefreshing}>
            {isRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
          </IconButton> */}
        </Grid>
        {/* <TextField
            label="Search by Transaction ID"
            variant="outlined"
            value={searchTransactionId}
            // onChange={handleTransactionSearch}
            size="small"
            autoComplete='off'
          /> */}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            maxWidth: { xs: "100%", sm: "50%" }, // Dynamic width for responsiveness
            borderRadius: "10px",
            border: "1px solid rgba(0, 0, 0, 0.3)",
            padding: "0.5rem",
          }}
        >
          <InputBase
            fullWidth
            placeholder="Search"
            value={searchQuery} // Bind the value of search input to the state
            onChange={handleSearchInput} // Only update searchQuery, no API call here
          />
          <IconButton color="primary" onClick={handleSearch}>
            {" "}
            {/* Trigger search when icon is clicked */}
            <SearchIcon />
          </IconButton>
        </Box>
      </Grid>

      <Grid style={{ marginTop: "2%", marginBottom: "5%" }}>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "12px 12px 0px 0px" }}
        >
          <Table>
            <TableHead style={{ backgroundColor: "#027BFE", color: "white" }}>
              <TableRow>
                <TableCell style={{ color: "white" }}>Sr. No.</TableCell>
                <TableCell style={{ color: "white" }}>Txn ID</TableCell>
                <TableCell style={{ color: "white" }}>Utr No.</TableCell>
                <TableCell style={{ color: "white" }}>User Name</TableCell>
                <TableCell style={{ color: "white" }}>User ID</TableCell>
                <TableCell style={{ color: "white" }}>Account No.</TableCell>
                <TableCell style={{ color: "white" }}>Account Name</TableCell>
                <TableCell style={{ color: "white" }}>Date</TableCell>
                <TableCell style={{ color: "white" }}>
                  Opening Balance
                </TableCell>
                <TableCell style={{ color: "white" }}>Amount</TableCell>
                <TableCell style={{ color: "white" }}>
                  Closing Balance
                </TableCell>
                <TableCell style={{ color: "white" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((row, index) => (
                  <TableRow
                    key={index}
                    className={`${classes.tableRow} ${index % 2 === 0 ? classes.oddRow : classes.evenRow}`}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.externalRef}</TableCell>
                    <TableCell>{row.txnReferenceId}</TableCell>
                    <TableCell>{row.userdetails?.name}</TableCell>
                    <TableCell>{row.userdetails?.userid}</TableCell>
                    <TableCell>{row.payeeAccount}</TableCell>
                    <TableCell>{row.payeeName || "N/A"}</TableCell>
                    <TableCell>{row.timestamp}</TableCell>
                    <TableCell>
                      ₹{parseFloat(row.walletOpeningBalance).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      ₹{parseFloat(row.txnValue).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      ₹{parseFloat(row.walletClosingBalance).toFixed(2)}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10} style={{ textAlign: "center" }}>
                    No transactions available for the selected date range.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={totalTransactions}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            nextIconButtonProps={{ disabled: !hasMore }} // Disable Next button if no more pages
          />
        </TableContainer>
      </Grid>
    </Container>
  );
};

export default CardandBarChart;
