import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dialog, Grid, Button, Typography, Box, Fade } from "@material-ui/core";
import GreenCheckmark from "../Assets/SuccessandFailedGif/GreenCheckmark.gif";
import Logo from "../Assets/login/iPaisaLogo2.png";

const PaySuccess = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [decryptedData, setDecryptedData] = useState(null);
  const [walletBalance, setWalletBalance] = useState(0);

  const fetchWalletBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  const decryptPayload = async (encryptedData) => {
    try {
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/pg/decryptPayload",
        { data: encryptedData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === "Success") {
        const decrypted = response.data.data;
        setDecryptedData(decrypted);
      } else {
        console.error("Failed to decrypt data:", response.data.message);
      }
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  };

  useEffect(() => {
    fetchWalletBalance();

    // Extract the encrypted data from the URL path
    const path = window.location.pathname;
    const encryptedData = path.replace("/deeplinkpage/", "");

    if (encryptedData) {
      decryptPayload(encryptedData);
    }
  }, []);

  const initiatePayment = async (decryptedData) => {
    try {
      const paymentData = {
        txnid: decryptedData.txnid,
        amount: decryptedData.amount,
        productinfo: "Add Money",
        firstname: "Edsom",
        phone: decryptedData.phone,
        email: decryptedData.email,
        surl: decryptedData.surl,
        furl: decryptedData.furl,
        clientId: decryptedData.clientId,
      };

      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/ezpg/easebuzz/initiate-payment",
        paymentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseBody = response.data.body;
      const parsedBody = JSON.parse(responseBody);

      if (parsedBody.status === 1) {
        const accessKey = parsedBody.data;
        const paymentUrl = `https://pay.easebuzz.in/pay/${accessKey}`;
        const newWindow = window.open(paymentUrl, "_blank");

        if (newWindow) {
          newWindow.focus();

          // Detect when the new window is closed
          const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(checkWindowClosed);
              // Check payment status when the window is closed
              checkPaymentStatus(paymentData.txnid);
            }
          }, 1000);

          // Optionally set an unload event listener
          newWindow.onbeforeunload = () => {
            clearInterval(checkWindowClosed);
            checkPaymentStatus(paymentData.txnid);
          };
        } else {
          console.error("Access key not received from backend");
        }
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const checkPaymentStatus = async (txnid) => {
    try {
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/ezpg/transaction-api-v2",
        { txnid }
      );
      const result = response.data;
      if (result.status === "success") {
        console.log("Payment Successful");
        window.location.href = decryptedData.surl;
      } else {
        console.log("Payment Failed");
        window.location.href = decryptedData.furl;
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  const handlePayClick = () => {
    if (decryptedData) {
      initiatePayment(decryptedData);
    } else {
      console.log("Decrypted data is not available");
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          overflow: "visible",
          padding: "20px",
          textAlign: "center",
          position: "relative",
          borderRadius: "20px",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#fff",
          width: "300px",
          height: "250px",
        },
      }}
    >
      <Grid
        container
        justifyContent="center"
        style={{ position: "relative", marginBottom: "20px" }}
      >
        <Box
          style={{
            position: "absolute",
            top: "-50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            backgroundColor: "#f5f5f5",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            transform: isSuccess ? "rotateY(360deg)" : "rotateY(0)",
            transition: "transform 0.6s",
          }}
        >
          {!isSuccess ? (
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: 80,
                width: 80,
                backgroundColor: "transparent",
                borderRadius: "50%",
              }}
            />
          ) : (
            <img
              src={GreenCheckmark}
              alt="Success"
              style={{
                height: 95,
                width: 115,
                borderRadius: "50%",
                marginRight: "11%",
              }}
            />
          )}
        </Box>
      </Grid>

      <Typography
        style={{
          marginTop: "18%",
          fontWeight: "bold",
          fontSize: "16px",
          color: "#9FA2AB",
        }}
      >
        Pay Amount
      </Typography>
      <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
        ₹{decryptedData?.amount || "1225"}
      </Typography>

      <Grid
        container
        justifyContent="center"
        style={{ marginTop: "12%", gap: "10%" }}
      >
        <Button
          style={{
            backgroundColor: "#e6ebfd",
            color: "#0432ec",
            minWidth: "100px",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={() => console.log("Cancel")}
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "#0432EC",
            color: "white",
            minWidth: "100px",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={handlePayClick}
        >
          Pay
        </Button>
      </Grid>
    </Dialog>
  );
};

export default PaySuccess;
