import React from "react";
import { useLocation } from "react-router-dom";
import Minidrawer from "./components/Dashboard/Navbar";
import ResponsiveDrawer from "./components/Dashboard/Navbar2";
import { MobileView, BrowserView, TabletView,isDesktop } from "react-device-detect";

const Layout = ({ children }) => {
  const location = useLocation();

  // List of routes where the ResponsiveDrawer should not be rendered
  const excludedRoutes = [
    "/login",
    "/",
    "/career",
    "/aboutus",
    "/contactus",
    "/blogs",
    "/services",
    '/gallery',
    '/ourteam',
    "/signin",
    "/privacypolicy",
    "/faq",
    "/termsandconditions",
    "/refundandcancel",
    "/deeplinkpage/**",
    "/dashboard2",
    "/PaySuccess",
    "*" // Match any other route not explicitly listed
  ];

  const isExcludedRoute = excludedRoutes.includes(location.pathname.toLowerCase());
  const isAuthenticated = !!localStorage.getItem("jwtToken");
  const isSignInPage = location.pathname.toLowerCase() === "/signin";

  const layoutStyle = {
    backgroundColor: isSignInPage ? "transparent" : "#F5F7FA", // Conditionally apply background color
  };

  return (
    <React.Fragment>
      <MobileView style={layoutStyle}>
        <div style={{display:'flex'}}>
          {/* Ternary operator to conditionally render the ResponsiveDrawer */}
          {!isExcludedRoute && isAuthenticated ? <ResponsiveDrawer /> : null}
          {/* Main content area */}
          <div style={{ height: "100%", width: "100%" }}>
            {/* Render the child components */}
            <div>{children}</div>
          </div>
        </div>
      </MobileView>

      

      <BrowserView style={layoutStyle}>
        {/* Desktop view layout */}
        <div style={{ display: "flex" }}>
          {/* Always render ResponsiveDrawer for desktop view */}
          {!isExcludedRoute && isAuthenticated ? <ResponsiveDrawer /> : null}
          {/* Main content area */}
          <div style={{ height: "100%", width: "100%" }}>
            {/* Render the child components */}
            <div>{children}</div>
          </div>
        </div>
      </BrowserView>
      
    </React.Fragment>
  );
};

export default Layout;
