import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  FormLabel,
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Divider,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { Container } from "@material-ui/core";
import { ArrowBackIos } from "@mui/icons-material";

export default function PrepaidRecharge() {
  const [showPlans, setShowPlans] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [operatorData, setOperatorData] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(""); // To track selected radio button
  const [records, setRecords] = useState([]); // Ensure the initial state is an empty array
  const [selectedCategory, setSelectedCategory] = useState("TOPUP");
  const [operatorDetails, setOperatorDetails] = useState(null); // Store operator details if needed
  const [phoneNumber, setPhoneNumber] = useState(""); // Store the phone number
  const [showMoreDetails, setShowMoreDetails] = useState(null);

  const [plans, setPlans] = useState({
    TOPUP: [],
    "3G/4G": [],
    Romaing: [],
    COMBO: [],
  });

  const handleGetPlans = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken"); // Retrieve JWT token from localStorage
      if (!jwtToken) {
        console.error("No JWT token found in localStorage");
        return;
      }

      const response = await fetch(
        "https://api.ipaisa.co.in/myapp/auth/simpleplan?operator=Airtel&cricle=Maharashtra",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch plans");
      }

      const data = await response.json();

      // Assuming data.records.TOPUP contains the plan details, we map it into the required structure
      const mappedPlans = {
        TOPUP: data.records.TOPUP
          ? data.records.TOPUP.map((plan) => ({
              price: plan.rs,
              description: plan.desc,
              validity: plan.validity,
            }))
          : [],
        "3G/4G": data.records["3G/4G"]
          ? data.records["3G/4G"].map((plan) => ({
              price: plan.rs,
              description: plan.desc,
              validity: plan.validity,
            }))
          : [],
        Romaing: data.records.Romaing
          ? data.records.Romaing.map((plan) => ({
              price: plan.rs,
              description: plan.desc,
              validity: plan.validity,
            }))
          : [],
        COMBO: data.records.COMBO
          ? data.records.COMBO.map((plan) => ({
              price: plan.rs,
              description: plan.desc,
              validity: plan.validity,
            }))
          : [],
      };

      setPlans(mappedPlans); // Set plans in state
      setShowPlans(true); // Show plans after fetching
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedRecord(""); // Reset selected record
  };

  const handleRecordChange = (event) => {
    setSelectedRecord(event.target.value);
  };
  const handleBack = () => {
    setOperatorData(null);
    setSelectedRecord("");
    setRecords([]);
    setSelectedCategory("");
    setOperatorDetails(null);
    setShowPlans(false);
  };
  const handleClose = () => setModalOpen(false);
  const [selectedPlan, setSelectedPlan] = useState({
    price: "",
    description: "",
    validity: "",
  });
  const handlePay = (plan) => {
    if (plan && plan.price) {
      setSelectedPlan({
        price: plan.price,
        description: plan.description,
        validity: plan.validity,
      });
      setModalOpen(true); // Open the dialog only when a valid plan is selected
    }
  };

  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
    setError(""); // Reset error message when the user types
  };

  const handleSubmit = async () => {
    // Validate mobile number (should be 10 digits)
    if (mobileNumber.length !== 10 || isNaN(mobileNumber)) {
      setError("Please enter a valid 10-digit mobile number.");
      return;
    }

    try {
      // API call to check the operator for the given mobile number
      const response = await fetch(
        `https://api.ipaisa.co.in/myapp/auth/OperatorCheck?tel=${mobileNumber}`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDIxNTU5OTI3IiwiaWF0IjoxNzMxNzYxODA1LCJleHAiOjE3MzE4MjE4MDV9.reFOak4vrzKkoaovTo7mWXETFPAETvO2DF53EO1R9YssdaLhlkqmmyBToK9FJ2rJwKdQwJssljf3CaciVVT9bw",
          },
        }
      );

      const data = await response.json();
      if (data && data.tel) {
        setPhoneNumber(data.tel); // Save the phone number from response
        setOperatorDetails(data.records); // Save the operator details if needed
      }
      if (response.ok) {
        // Success: Set the response data
        const { Operator, circle } = data.records; // Extracting Operator and Circle from response
        setOperatorData({ operator: Operator, circle: circle }); // Store data in state
        // setModalOpen(true); // Show the modal with operator data
      } else {
        // Handle errors returned from the API
        setError(data?.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      setError("Error connecting to the API.");
    }
  };
  const handleProceedToPay = async () => {
    if (!phoneNumber) {
      console.error("Phone number is missing.");
      return;
    }

    const payload = {
      mn: phoneNumber, // Using the phone number from operator check response
      amt: selectedPlan.price, // Set the amount you want to pay
      providerName: operatorDetails.Operator, // Set the provider
      type: "prepaid recharge", // Set the type (prepaid or other)
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
    };

    try {
      const token = localStorage.getItem("jwtToken");
      const response = await fetch(
        "https://api.ipaisa.co.in/myapp/auth/billPay/recharge",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("Payment response:", data);
      // Handle success (you may want to display a success message or take further actions)
    } catch (error) {
      console.error("Error in payment API", error);
    }
  };

  return (
    <Grid>
      {!showPlans ? (
        <>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                fullWidth
                value={mobileNumber}
                onChange={handleMobileChange}
                onBlur={handleSubmit}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Operator"
                variant="outlined"
                fullWidth
                value={operatorData ? operatorData.operator : ""}
                disabled
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Circle"
                variant="outlined"
                fullWidth
                value={operatorData ? operatorData.circle : ""}
                disabled
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: "2%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleGetPlans}
              style={{
                color: "white",
                backgroundColor: "#027BFE",
                textTransform: "none",
                fontSize: "16px",
              }}
            >
              Get Plans
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Button
            onClick={handleBack}
            style={{
              backgroundColor: "#ccc",
              color: "#333",
              textTransform: "none",
            }}
          >
            <ArrowBackIos style={{ fontSize: "16px" }} />
            Back
          </Button>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              color: "black",
              marginTop: "1%",
            }}
          >
            Select Recharge Plan
          </FormLabel>
          <RadioGroup
            value={selectedCategory}
            onChange={handleCategoryChange}
            row
            style={{
              width: "900px",
              height: "22px",
              gap: "24px",
              opacity: "1",
            }}
          >
            <FormControlLabel value="TOPUP" control={<Radio />} label="TOPUP" />
            <FormControlLabel value="3G/4G" control={<Radio />} label="3G/4G" />
            <FormControlLabel
              value="Romaing"
              control={<Radio />}
              label="Romaing"
            />
            <FormControlLabel value="COMBO" control={<Radio />} label="COMBO" />
          </RadioGroup>
          {showPlans && plans[selectedCategory] && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {plans[selectedCategory].map((plan, index) => (
                <Grid item md={3} xs={12} key={index}>
                  <Grid
                    sx={{
                      border: "1px solid #D9D9D9",
                      borderRadius: "12px",
                      cursor: "pointer",
                      height: "auto", // Ensure equal height
                      display: "flex",
                      flexDirection: "column",
                      "&:hover": {
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                    onClick={() => handlePay(plan)} // Only open dialog on valid plan click
                  >
                    <CardContent>
                      <Typography variant="h6" fontWeight="bold">
                        ₹{plan.price}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {plan.validity}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2">
                        {plan.description.length > 70
                          ? `${plan.description.substring(0, 70)}...` // Show part of the description
                          : plan.description}
                      </Typography>
                      {plan.description.length > 70 && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => setShowMoreDetails(index)} // Handle "More" button click
                          sx={{ marginTop: "8px" }}
                        >
                          More
                        </Button>
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}

          {/* Render full description if "More" is clicked */}
          {showMoreDetails !== null && (
            <Dialog
              open={true}
              onClose={() => setShowMoreDetails(null)}
              fullWidth
              maxWidth="md"
            >
              <DialogContent>
                <Typography variant="h6" fontWeight="bold">
                  Full Description:
                </Typography>
                <Typography variant="body2">
                  {plans[selectedCategory][showMoreDetails].description}
                </Typography>
                <Button
                  onClick={() => setShowMoreDetails(null)}
                  sx={{
                    mt: 2,
                    color: "white",
                    backgroundColor: "#027BFE",
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                >
                  Close
                </Button>
              </DialogContent>
            </Dialog>
          )}
        </>
      )}
    </Grid>
  );
}
