import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
} from "@mui/material";
import axios from "axios";
import { PartnerArrowIcon } from "../../res/NewWebIcons";

const PayinHierarchywise = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const userId = "AD2131"; // Replace with dynamic user ID if needed
  const fromDate = "2024-10-01"; // Replace with dynamic from date if needed
  const toDate = "2024-10-31"; // Replace with dynamic to date if needed

  // Function to fetch data
  const fetchData = async (userId, fromDate, toDate) => {
    try {
      const token = localStorage.getItem("jwtToken"); // Get token from localStorage
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/admin/getAllPyInTxnsByUserId?userId=${userId}&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in the request headers
          },
        }
      );
      setData(response.data.body.data); // Set the fetched data
    } catch (error) {
      console.error("There was an error fetching data!", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchData(userId, fromDate, toDate); // Call fetchData on component mount
  }, []);

  return (
    <Container style={{ padding: "1rem", marginTop: "calc(4% + 58px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Reports
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "14px" }}
        >
          Hierarchywise Payin
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "1%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Hierarchywise Payin
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "1%" }}>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "12px 12px 0px 0px" }}
          >
            <Table>
              <TableHead style={{ backgroundColor: "#027BFE", color: "white" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>ID</TableCell>
                  <TableCell style={{ color: "white" }}>
                    Transaction ID
                  </TableCell>
                  <TableCell style={{ color: "white" }}>First Name</TableCell>
                  <TableCell style={{ color: "white" }}>Email</TableCell>
                  <TableCell style={{ color: "white" }}>Phone</TableCell>
                  <TableCell style={{ color: "white" }}>Mode</TableCell>
                  <TableCell style={{ color: "white" }}>Status</TableCell>
                  <TableCell style={{ color: "white" }}>Amount</TableCell>
                  <TableCell style={{ color: "white" }}>
                    Bank Reference
                  </TableCell>
                  <TableCell style={{ color: "white" }}>Added On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? ( // Show loading indicator while fetching data
                  <TableRow>
                    <TableCell colSpan={10} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.txnid}</TableCell>
                      <TableCell>{row.firstname}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.phone}</TableCell>
                      <TableCell>{row.mode}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>{row.bankRefNum}</TableCell>
                      <TableCell>
                        {new Date(row.addedon).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PayinHierarchywise;
