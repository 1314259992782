import React from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import axios from "axios";

const PostpaidDetails = ({ response, opCode }) => {
  if (!response || !response.records || response.records.length === 0) {
    return <Typography>No records available</Typography>;
  }
  console.log(opCode, "GHeeee");

  const { CustomerName, BillNumber, Billdate, Billamount, Duedate } =
    response.records[0];

  const handlePay = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        console.error("JWT Token not found in localStorage");
        return;
      }

      const payload = {
        mn: response.tel || "", // Mobile number
        amt: Billamount || "", // Bill amount
        opCode: opCode, // Operator
        type: "postpaid recharge", // Recharge type
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
      };

      console.log("Payload:", payload); // Debugging

      const apiUrl = "https://api.ipaisa.co.in/myapp/auth/billPay/recharge";

      const apiResponse = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Payment Successful:", apiResponse.data);
      alert("Payment Successful");
    } catch (error) {
      console.error("Payment Failed:", error.response || error.message);
      alert("Payment Failed. Please try again.");
    }
  };

  const handleCancel = () => {
    alert("Payment Cancelled");
  };

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h6">Postpaid Details</Typography>
      <TextField
        label="Customer Name"
        value={CustomerName}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Bill Number"
        value={BillNumber}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Bill Date"
        value={Billdate}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Bill Amount"
        value={Billamount}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Due Date"
        value={Duedate}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        variant="outlined"
      />
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        <Button
          variant="outlined"
          color="error"
          fullWidth
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handlePay}
        >
          Pay
        </Button>
      </Box>
    </Box>
  );
};

export default PostpaidDetails;
