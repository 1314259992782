import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../Assets/login/iPaisaLogo2.png";

const SetPassword = ({ open, onClose, onMpinValid }) => {
  const [newPassword, setNewPassword] = useState(["", "", "", ""]);
  const [confirmPassword, setConfirmPassword] = useState(["", "", "", ""]);
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const newPassRefs = useRef([]);
  const confirmPassRefs = useRef([]);

  const handleSetPassword = async () => {
    if (newPassword.join("") !== confirmPassword.join("")) {
      setPasswordError("IPIN must match");
      return;
    }

    setPasswordError("");
    setLoading(true);
    const mpin = newPassword.join("");
    const token = localStorage.getItem("jwtToken");

    try {
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/setTxnMpin",
        { mpin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.body) {
        const message = response.data.body.message;

        if (message === "Mpin alredy Set") {
          localStorage.setItem("isTxnMpinSet", "true");
          toast.info("Mpin already set.", {
            position: "top-right",
            autoClose: 3000,
          });
          setTimeout(() => onClose(), 3000);
          onMpinValid();
        } else {
          toast.success(message, { position: "top-right", autoClose: 3000 });
          setNewPassword(["", "", "", ""]);
          setConfirmPassword(["", "", "", ""]);
          setPasswordError("");
          setApiError("");
          localStorage.setItem("isTxnMpinSet", "true");

          // Call onMpinValid to proceed with payout
          onMpinValid();

          setTimeout(() => onClose(), 3000);
        }
      }
    } catch (error) {
      setApiError("Failed to set IPIN. Please try again.");
      toast.error("Failed to set IPIN. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNewPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...newPassword];
      updatedPassword[index] = value;
      setNewPassword(updatedPassword);
      if (index < 3) newPassRefs.current[index + 1].focus();
    }
  };

  const handleConfirmPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...confirmPassword];
      updatedPassword[index] = value;
      setConfirmPassword(updatedPassword);
      if (index < 3) confirmPassRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index, passwordType) => {
    const refs = passwordType === "new" ? newPassRefs : confirmPassRefs;
    const password = passwordType === "new" ? newPassword : confirmPassword;
    const setPassword =
      passwordType === "new" ? setNewPassword : setConfirmPassword;

    if (password[index] !== "") {
      const updatedPassword = [...password];
      updatedPassword[index] = "";
      setPassword(updatedPassword);
      refs.current[index].focus();
    } else if (index > 0) {
      refs.current[index - 1].focus();
    }
  };

  const renderPasswordFields = (
    password,
    handleChange,
    handleBackspace,
    refs,
    passwordType
  ) => (
    <Grid container spacing={2}>
      {password.map((char, index) => (
        <Grid item key={index}>
          <TextField
            inputProps={{
              maxLength: 1,
              sx: {
                width: "48px",
                height: "48px",
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid #ccc",
              },
            }}
            value={char}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => {
              if (e.key === "Backspace") handleBackspace(index, passwordType);
            }}
            inputRef={(el) => (refs.current[index] = el)}
            type="password"
            variant="outlined"
          />
        </Grid>
      ))}
    </Grid>
  );

  const handleCancel = () => {
    setNewPassword(["", "", "", ""]);
    setConfirmPassword(["", "", "", ""]);
    setPasswordError("");
    setApiError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: "#027BFE", fontWeight: "bold" }}>
          Set Your IPIN!
        </Typography>
        <img
          src={logo}
          alt="iPaisa Logo"
          style={{ height: 58, width: 58, borderRadius: "50%" }}
        />
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Welcome! Please Create A Strong IPIN For Your Account.
        </Typography>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">New IPIN</Typography>
          {renderPasswordFields(
            newPassword,
            handleNewPasswordChange,
            handleBackspace,
            newPassRefs,
            "new"
          )}
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1">Confirm New IPIN</Typography>
          {renderPasswordFields(
            confirmPassword,
            handleConfirmPasswordChange,
            handleBackspace,
            confirmPassRefs,
            "confirm"
          )}
          {passwordError && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {passwordError}
            </Typography>
          )}
          {apiError && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {apiError}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleCancel}
          disabled={loading}
          sx={{
            width: "18%",
            height: "50px",
            borderRadius: "8px",
            color: "#808080",
            border: "1px solid #D9D9D9",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSetPassword}
          disabled={loading}
          sx={{
            backgroundColor: "#027BFE",
            color: "white",
            width: "18%",
            borderRadius: "8px",
            height: "50px",
            ":hover": { backgroundColor: "#0056b3" },
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Set IPIN"
          )}
        </Button>
      </DialogActions>

      <ToastContainer />
    </Dialog>
  );
};

export default SetPassword;
