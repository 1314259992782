import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Container,
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CardMedia,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import logo from "../Assets/login/iPaisaLogo2.png";
import { NewBBPSIcon } from "../../res/NewWebIcons";

const RRecharge = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [operatorOptions, setOperatorOptions] = useState([]);
  const [circleOptions, setCircleOptions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(""); // State to store the selected plan

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      operator: "",
      circle: "",
      amount: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number")
        .required("Mobile number is required"),
      operator: Yup.string().required("Operator is required"),
      circle: Yup.string().required("Circle is required"),
      amount: Yup.string()
        .matches(/^[1-9][0-9]*$/, "Amount must be a positive number")
        .required("Amount is required"),
    }),
    onSubmit: async (values) => {
      console.log("Form Submitted with Values: ", values);
      console.log("Selected Plan: ", selectedPlan); // Log selected plan here
      setSubmitSuccess(true);
    },
  });

  const handleMobileBlur = async (e) => {
    const mobileNumber = e.target.value;
    if (/^[0-9]{10}$/.test(mobileNumber)) {
      try {
        const response = await axios.get(
          `https://api.ipaisa.co.in/myapp/auth/OperatorCheck?tel=${mobileNumber}`,
          {
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDIxNTU5OTI3IiwiaWF0IjoxNzMxNzYxODA1LCJleHAiOjE3MzE4MjE4MDV9.reFOak4vrzKkoaovTo7mWXETFPAETvO2DF53EO1R9YssdaLhlkqmmyBToK9FJ2rJwKdQwJssljf3CaciVVT9bw",
            },
          }
        );

        const { Operator, circle } = response.data.records;

        if (Operator) {
          setOperatorOptions([Operator]);
          formik.setFieldValue("operator", Operator);
        }

        if (circle) {
          setCircleOptions([circle]);
          formik.setFieldValue("circle", circle);
        }

        // Fetch plans based on operator and circle
        if (Operator && circle) {
          fetchPlans(Operator, circle);
        }
      } catch (error) {
        console.error("Error fetching operator details:", error);
      }
    }
  };

  const fetchPlans = async (operator, circle) => {
    try {
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/simpleplan?operator=${operator}&cricle=${circle}`,
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5MDIxNTU5OTI3IiwiaWF0IjoxNzMxNzYxODA1LCJleHAiOjE3MzE4MjE4MDV9.reFOak4vrzKkoaovTo7mWXETFPAETvO2DF53EO1R9YssdaLhlkqmmyBToK9FJ2rJwKdQwJssljf3CaciVVT9bw",
          },
        }
      );

      const records = response.data.records;
      const plansArray = [];

      // Flatten the records object into an array
      Object.keys(records).forEach((category) => {
        records[category].forEach((plan) => {
          plansArray.push({
            ...plan,
            category,
          });
        });
      });

      setPlans(plansArray); // Flattened array of plans
      console.log("Plans fetched successfully:", plansArray);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode || e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value); // Update selected plan
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "7%", marginBottom: "2%" }}>
      <Box
        mt={4}
        p={4}
        boxShadow={2}
        bgcolor="#DDF1F5"
        borderRadius={8}
        sx={{
          background: "linear-gradient(135deg, #0B1E59 0%, #1840BF 100%)",
          color: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} style={{ marginTop: "1%" }}>
            <CardMedia
              component="img"
              image={logo}
              alt="iPaisa Logo"
              style={{
                backgroundColor: "transparent",
                borderRadius: "50%",
                marginBottom: "4%",
              }}
              sx={{
                height: { xs: 50, md: 90 },
                width: { xs: 50, md: 90 },
                mt: { xs: 1, md: 1 },
              }}
            />
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", fontSize: "35px" }}
            >
              Instant Prepaid Mobile Recharge Solution
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box p={4} bgcolor="white" boxShadow={2} borderRadius={8}>
              <Typography
                variant="h5"
                color="#027BFE"
                gutterBottom
                sx={{ fontWeight: "bold", fontSize: "30px" }}
              >
                Mobile Recharge
              </Typography>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="mobileNumber"
                  name="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    handleMobileBlur(e);
                  }}
                  error={
                    formik.touched.mobileNumber &&
                    Boolean(formik.errors.mobileNumber)
                  }
                  helperText={
                    formik.touched.mobileNumber && formik.errors.mobileNumber
                  }
                  InputProps={{
                    inputProps: {
                      maxLength: 10,
                    },
                  }}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="operator-label">Operator</InputLabel>
                <Select
                  labelId="operator-label"
                  id="operator"
                  name="operator"
                  value={formik.values.operator}
                  onChange={formik.handleChange}
                >
                  {operatorOptions.map((op, index) => (
                    <MenuItem key={index} value={op}>
                      {op}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="circle-label">Circle</InputLabel>
                <Select
                  labelId="circle-label"
                  id="circle"
                  name="circle"
                  value={formik.values.circle}
                  onChange={formik.handleChange}
                >
                  {circleOptions.map((circle, index) => (
                    <MenuItem key={index} value={circle}>
                      {circle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RadioGroup value={selectedPlan} onChange={handlePlanChange}>
                {plans.map((plan, index) => (
                  <FormControlLabel
                    key={index}
                    value={plan.rs} // Plan's value (price) is used for selection
                    control={<Radio />}
                    label={`₹${plan.rs} - ${plan.desc}`}
                  />
                ))}
              </RadioGroup>
              <FormControl fullWidth margin="normal">
                <TextField
                  id="amount"
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </FormControl>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
              >
                Proceed to Recharge
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default RRecharge;
