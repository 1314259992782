import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  Paper,
  TextField,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling
import logo from "../Assets/login/iPaisaLogo2.png";
import { PartnerArrowIcon } from "../../res/NewWebIcons";

const ResetPassword2 = () => {
  const [newPassword, setNewPassword] = useState(["", "", "", ""]);
  const [confirmPassword, setConfirmPassword] = useState(["", "", "", ""]);
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const newPassRefs = useRef([]);
  const confirmPassRefs = useRef([]);

  const handleSetPassword = async () => {
    if (newPassword.join("") !== confirmPassword.join("")) {
      setPasswordError("IPIN must match");
      return;
    }

    setPasswordError("");
    const mpin = newPassword.join(""); // Combine the new password array into a string

    // Retrieve the JWT token from localStorage
    const jwtToken = localStorage.getItem("jwtToken");

    try {
      const response = await axios.put(
        "https://api.ipaisa.co.in/myapp/auth/updateMpin",
        { mpin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`, // Use the JWT token here
          },
        }
      );

      if (response.data && response.data.body) {
        // Show toast with the response message
        toast.success(response.data.body.message, {
          position: "top-right", // Use string value 'top-right'
          autoClose: 3000, // auto close after 3 seconds
        });
      }
    } catch (error) {
      setApiError("Failed to set IPIN. Please try again.");
      console.error("API Error:", error);

      // Show error toast
      toast.error("Failed to set IPIN. Please try again.", {
        position: "top-right", // Use string value 'top-right'
        autoClose: 3000,
      });
    }
  };

  const handleNewPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...newPassword];
      updatedPassword[index] = value;
      setNewPassword(updatedPassword);
      if (index < 3) {
        newPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleConfirmPasswordChange = (value, index) => {
    if (/^\d$/.test(value)) {
      const updatedPassword = [...confirmPassword];
      updatedPassword[index] = value;
      setConfirmPassword(updatedPassword);
      if (index < 3) {
        confirmPassRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, passwordType) => {
    if (passwordType === "new") {
      const updatedPassword = [...newPassword];
      if (updatedPassword[index] !== "") {
        updatedPassword[index] = "";
        setNewPassword(updatedPassword);
        newPassRefs.current[index].focus();
      } else if (index > 0) {
        newPassRefs.current[index - 1].focus();
      }
    } else {
      const updatedPassword = [...confirmPassword];
      if (updatedPassword[index] !== "") {
        updatedPassword[index] = "";
        setConfirmPassword(updatedPassword);
        confirmPassRefs.current[index].focus();
      } else if (index > 0) {
        confirmPassRefs.current[index - 1].focus();
      }
    }
  };

  const renderPasswordFields = (
    password,
    handleChange,
    handleBackspace,
    refs,
    passwordType
  ) => {
    return (
      <Grid container spacing={2}>
        {password.map((char, index) => (
          <Grid item key={index}>
            <TextField
              inputProps={{
                maxLength: 1,
                sx: {
                  width: "30px",
                  height: "30px",
                  textAlign: "center",
                  borderRadius: "6px 6px 6px 6px",
                  border: "1px solid #ccc",
                },
              }}
              value={char}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  handleBackspace(index, passwordType);
                }
              }}
              inputRef={(el) => (refs.current[index] = el)}
              type="password"
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleCancel = () => {
    setNewPassword(["", "", "", ""]);
    setConfirmPassword(["", "", "", ""]);
    setPasswordError("");
    setApiError("");
  };

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1%",
          justifyContent: "start",
        }}
      >
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Settings
        </Typography>
        <PartnerArrowIcon />
        <Typography
          color="textSecondary"
          style={{ fontFamily: "sans-serif", fontSize: "12px" }}
        >
          Reset Your IPIN
        </Typography>
      </Grid>
      <Grid style={{ marginTop: "2%" }}>
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "sans-serif",
            fontSize: "19px",
            color: "#343C6A",
          }}
        >
          Reset Your IPIN
        </Typography>
      </Grid>

      <Paper
        elevation={2}
        sx={{ padding: "2rem", mt: 3, borderRadius: "12px" }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: "14px" }} gutterBottom>
            Welcome! Please Create A Strong IPIN For Your Account.
          </Typography>
          <Grid>
            <img
              src={logo}
              alt="iPaisa Logo"
              style={{
                height: 58,
                width: 58,
                backgroundColor: "transparent",
                borderRadius: "50%",
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle1">New IPIN</Typography>
          {renderPasswordFields(
            newPassword,
            handleNewPasswordChange,
            handleBackspace,
            newPassRefs,
            "new"
          )}
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">Confirm New IPIN</Typography>
          {renderPasswordFields(
            confirmPassword,
            handleConfirmPasswordChange,
            handleBackspace,
            confirmPassRefs,
            "confirm"
          )}
          {passwordError && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {passwordError}
            </Typography>
          )}
          {apiError && (
            <Typography variant="caption" color="error" sx={{ mt: 1 }}>
              {apiError}
            </Typography>
          )}
        </Box>

        <Grid item xs={12} style={{ marginTop: "4%" }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Button
              onClick={handleCancel}
              sx={{
                textTransform: "none",
                width: "18%",
                height: "50px",
                borderRadius: "8px",
                color: "#808080",
                border: "1px solid #D9D9D9",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSetPassword}
              sx={{
                backgroundColor: "#027BFE",
                color: "white",
                textTransform: "none",
                width: "18%",
                borderRadius: "8px",
                height: "50px",
                ":hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Reset IPIN
            </Button>
          </Box>
        </Grid>
      </Paper>

      {/* Toast notification container */}
      <ToastContainer />
    </Container>
  );
};

export default ResetPassword2;
