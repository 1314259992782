import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Paper,
  Box,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { Walllet_Icon } from "../../res/icons";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BalanceTopComponent from "./BalanceTopComponent";
import WalletBalanceComponent from "./WalletBalanceComponent";
import { PartnerArrowIcon } from "../../res/NewWebIcons";
import SuccessPop from "./SuccessPopup";
import FailedPop from "./FailedPopup";
import UserNotExist from "./UserNotExist";
import InsufficiantB from "./InsufficiantB";
import IpinModal from "../IpaisaMpin/IpinModal";
import SetPasswordModal from "../IpaisaMpin/SetIPMpin"; // Import SetPassword modal

const NewFundTransfer = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [payoutType, setPayoutType] = useState(""); // State for dropdown selection
  const [showSuccessPop, setShowSuccessPop] = useState(false);
  const [showFailedPop, setShowFailedPop] = useState(false);
  const [showUserNotExist, setShowUserNotExist] = useState(false);
  const [showInsufficiantB, setShowInsufficiantB] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openIpinModal, setOpenIpinModal] = useState(false);
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false); // State to control SetPassword modal
  const [transactionValues, setTransactionValues] = useState(null);
  const [resetFormFunc, setResetFormFunc] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [users, setUsers] = useState(null);
  const handleClose = () => {
    setShowSuccessPop(false);
    setShowFailedPop(false);
    setShowInsufficiantB(false);
    setShowUserNotExist(false);
  };

  const fetchBalance = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getwalletbalance",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWalletBalance(response.data.walletBalance || 0);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  const handleOpenModal = () => {
    const isTxnMpinSet = localStorage.getItem("isTxnMpinSet");

    if (isTxnMpinSet === "False") {
      toast.error("Please set IPin first.");

      // Use setTimeout to open SetPasswordModal after 3 seconds
      setTimeout(() => {
        setOpenSetPasswordModal(true);
      }, 3000);
    } else {
      setOpenIpinModal(true); // Open IPin modal
    }
  };

  const handleCloseModal = () => {
    setOpenIpinModal(false);
    setOpenSetPasswordModal(false); // Close SetPassword modal
  };

  useEffect(() => {
    fetchBalance();
  }, [walletBalance]);

  const validationSchema = Yup.object().shape({
    receiverId: Yup.string()
      .required("Receiver ID is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Receiver ID should only contain alphanumeric characters"
      ),
    amount: Yup.string()
      .matches(
        /^\d+(\.\d{1,2})?$/,
        "Amount should be a positive number and can contain up to two decimal places"
      )
      .test("is-positive", "Amount must be a positive number", (value) => {
        return Number(value) > 0;
      })
      .required("Amount is required"),
  });

  const handleTransaction = async (values) => {
    setLoading(true);
    console.log("Transaction Values:", values); // Log the values
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/transaction",
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Transaction Response:", response.data); // Log the response

      if (response.data.status === "Balance is not sufficient") {
        toast.error("Balance is not sufficient");
        setShowInsufficiantB(true);
      } else if (response.data.statusCode === "OK") {
        toast.success(
          response.data.message || "Funds transferred successfully"
        );
        setShowSuccessPop(true);
        fetchBalance(); // Refresh wallet balance
        if (resetFormFunc) resetFormFunc(); // Reset the form
      } else {
        toast.error(response.data.message || "Please enter a valid User ID");
        setShowFailedPop(true);
      }
    } catch (error) {
      console.error("Transaction error:", error); // Log any errors
      toast.error("An error occurred during the fund transfer");
    } finally {
      setLoading(false); // Reset loading state after transaction
    }
  };

  const handleMpinValidation = async (mpin) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.post(
        "https://api.ipaisa.co.in/myapp/auth/validateTxnMpin",
        { mpin },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.statusCodeValue === 200 &&
        response.data.body.message === "MPIN is valid"
      ) {
        // MPIN is valid, proceed with transaction
        await handleTransaction(transactionValues); // Ensure this function is being called
        handleCloseModal(); // Close MPIN modal
      } else {
        toast.error("Invalid MPIN.");
      }
    } catch (error) {
      console.error("Error validating MPIN:", error);
      toast.error("Error validating MPIN.");
    }
  };
  const handleMpinValid = () => {
    if (transactionValues) {
      handleTransaction(transactionValues);
    }
  };

  const formik = useFormik({
    initialValues: {
      receiverId: "",
      receiverName: "",
      receiverType: "",
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (values.amount > walletBalance) {
        toast.error("Insufficient wallet balance.");
        setShowInsufficiantB(true);
        return;
      }
      // Set transaction values and reset function for use in MPIN validation
      setTransactionValues(values);
      setResetFormFunc(() => resetForm); // Save the resetForm function
      handleOpenModal(); // Call function to check MPIN and show correct modal
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Only sanitize non-remark fields by replacing multiple spaces with a single space and trimming leading/trailing spaces
    const sanitizedValue =
      name === "remark" ? value : value.replace(/\s+/g, " ").trim();

    formik.setFieldValue(name, sanitizedValue);
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;

    // Allow only digits and a single decimal point
    let validValue = value.replace(/[^0-9.]/g, "");

    // Ensure only one decimal point is allowed
    const parts = validValue.split(".");
    if (parts.length > 2) {
      validValue = parts[0] + "." + parts[1].slice(0, 2); // Keep only the first decimal part and trim to two decimal places
    }

    // Remove leading zeros (except in cases like "0.5")
    if (
      validValue.startsWith("0") &&
      validValue[1] !== "." &&
      validValue.length > 1
    ) {
      validValue = validValue.replace(/^0+/, "");
    }

    // Set the formatted value back in the formik field
    formik.setFieldValue(name, validValue);
  };

  const fetchHierarchyData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/gethierarchy-id-name",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Assuming response.data.users is an array of user objects
      setUsers(response.data.users || []);
    } catch (error) {
      console.error("Error fetching hierarchy data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHierarchyData(); // Fetch data on mount
  }, []);

  const fetchReceiverDetails = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        `https://api.ipaisa.co.in/myapp/auth/reciverUnderSender/${formik.values.receiverId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { firstName, lastName, uType } = response.data;
      const receiverName = `${firstName} ${lastName}`; // Concatenate first and last names
      const receiverType = uType; // Assign uType to receiverType
      formik.setValues({
        ...formik.values,
        receiverName,
        receiverType,
      });
    } catch (error) {
      console.log("Error fetching receiver details:", error);
    }
  };

  const handlePayoutTypeChange = (event) => {
    setPayoutType(event.target.value);
  };
  const lineHeight = "50px";

  return (
    <Container style={{ padding: "2rem", marginTop: "calc(3% + 56px)" }}>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1%",
            justifyContent: "start",
          }}
        >
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "13px" }}
          >
            Balance
          </Typography>
          <PartnerArrowIcon />
          <Typography
            color="textSecondary"
            style={{ fontFamily: "sans-serif", fontSize: "13px" }}
          >
            Fund Transfer
          </Typography>
        </Grid>

        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1%",
          }}
        >
          <Grid>
            <Typography
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#343C6A",
              }}
            >
              Fund Transfer
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "2%" }}>
        {/* {/ Wallet Balance Card /} */}
        <WalletBalanceComponent walletBalance={walletBalance} />

        {/* {/ Fund Transfer Form /} */}
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          <Paper
            style={{ boxShadow: "none", padding: "2rem", borderRadius: "8px" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Pay Type</InputLabel>
                    <Select
                      value={payoutType}
                      onChange={handlePayoutTypeChange}
                      label="Payout Type"
                    >
                      <MenuItem value="payout">Single Fund Trasnfer</MenuItem>
                      <MenuItem value="bulk-payout">
                        Bulk Fund Trasnfer
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={users}
                    getOptionLabel={(option) =>
                      `${option.mobileNumber} - ${option.usernname} (${option.userId})`
                    }
                    loading={loading}
                    onChange={(event, value) => {
                      formik.setFieldValue(
                        "receiverId",
                        value ? value.mobileNumber : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Receiver ID"
                        name="receiverId"
                        value={formik.values.receiverId}
                        onChange={formik.handleChange}
                        fullWidth
                        required
                        error={
                          formik.touched.receiverId &&
                          Boolean(formik.errors.receiverId)
                        }
                        helperText={
                          formik.touched.receiverId && formik.errors.receiverId
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                              <Button
                                onClick={fetchReceiverDetails}
                                style={{
                                  marginLeft: 8,
                                  backgroundColor: "#027BFE",
                                  color: "#FFFFFF",
                                }}
                              >
                                <SearchIcon />
                              </Button>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    disabled
                    label="Receiver Name"
                    name="receiverName"
                    value={formik.values.receiverName}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { height: "2.5em" },
                    }}
                    error={
                      formik.touched.receiverName &&
                      Boolean(formik.errors.receiverName)
                    }
                    helperText={
                      formik.touched.receiverName && formik.errors.receiverName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    required
                    error={
                      formik.touched.receiverType &&
                      Boolean(formik.errors.receiverType)
                    }
                  >
                    <InputLabel>Receiver Type</InputLabel>
                    <Select
                      name="receiverType"
                      value={formik.values.receiverType}
                      onChange={handleInputChange}
                      label="Receiver Type"
                      readOnly
                      disabled
                    >
                      <MenuItem value="CHANNELPARTNER">
                        Channel Partner
                      </MenuItem>
                      <MenuItem value="SUPERDISTRIBUTOR">
                        Super Distributor
                      </MenuItem>
                      <MenuItem value="MASTERDISTRIBUTOR">
                        Master Distributor
                      </MenuItem>
                      <MenuItem value="AREADISTRIBUTOR">
                        Area Distributor
                      </MenuItem>
                      <MenuItem value="RETAILER">Retailer</MenuItem>
                    </Select>
                    {formik.touched.receiverType &&
                      formik.errors.receiverType && (
                        <Typography color="error" variant="caption">
                          {formik.errors.receiverType}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Amount"
                    name="amount"
                    type="text"
                    autoComplete="off"
                    value={formik.values.amount}
                    onChange={handleAmountChange}
                    fullWidth
                    required
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Remark"
                    name="remark"
                    value={formik.values.remark}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    multiline
                    rows={4}
                    error={
                      formik.touched.remark && Boolean(formik.errors.remark)
                    }
                    helperText={formik.touched.remark && formik.errors.remark}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="end"
                    gap={1}
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                  >
                    <Button
                      sx={{
                        border: "1.5px solid #FF0000",
                        height: lineHeight,
                        fontSize: "16px",
                        color: "#FF0000",
                        textTransform: "none",
                        width: { xs: "100%", md: "18%" },
                        borderRadius: "8px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      // onClick={handleOpenModal}
                      sx={{
                        backgroundColor: "#027BFE",
                        height: lineHeight,
                        color: "#FFFFFF",
                        fontSize: "16px",
                        textTransform: "none",
                        width: { xs: "100%", md: "18%" },
                        borderRadius: "8px",
                        "&:hover": {
                          backgroundColor: "#027BFE",
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      {loading ? (
                        <CircularProgress
                          size={24}
                          style={{ color: "#FFFFFF" }}
                        />
                      ) : (
                        "Transfer Fund"
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {showSuccessPop && <SuccessPop handleClose={handleClose} />}
      {showFailedPop && (
        <FailedPop handleClose={handleClose} errorMessage={errorMessage} />
      )}
      {showUserNotExist && <UserNotExist handleClose={handleClose} />}
      {showInsufficiantB && <InsufficiantB handleClose={handleClose} />}
      <IpinModal
        open={openIpinModal}
        onClose={handleCloseModal}
        onMpinValid={handleMpinValid}
      />
      <SetPasswordModal
        open={openSetPasswordModal}
        onClose={handleCloseModal}
        onMpinValid={handleMpinValid}
      />
    </Container>
  );
};

export default NewFundTransfer;
