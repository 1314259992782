import axios from "axios";
import { SET_BANK_DETAILS, SET_ERROR } from "../actionTypes";

export const fetchBankDetails = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("jwtToken");
      const response = await axios.get(
        "https://api.ipaisa.co.in/myapp/auth/getBankDetail",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const bankData = response.data.body || [];
      dispatch({ type: SET_BANK_DETAILS, payload: bankData });
    } catch (error) {
      console.error("Error fetching bank details:", error);
      dispatch({ type: SET_ERROR, payload: "Failed to fetch bank details" });
    }
  };
};
