import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Typography,
  Card,
  CardContent,
  FormLabel,
} from "@mui/material";
import axios from "axios";

const NewWaterBill = () => {
  const [waterBoard, setWaterBoard] = useState("");
  const [consumerID, setConsumerID] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [consumerDetails, setConsumerDetails] = useState({
    customerName: "John Doe",
    id: "123456",
    amount: "₹500",
  });
  const [walletBalance, setWalletBalance] = useState("₹1000");
  const [waterBoards, setWaterBoards] = useState([]);

  useEffect(() => {
    const fetchWaterBoards = async () => {
      try {
        const response = await axios.get(
          "https://api.ipaisa.co.in/myapp/auth/getAllWaterServicePro",
          {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI4Mzc4MDU0MDkwIiwiaWF0IjoxNzMxNzgxNTI5LCJleHAiOjE3MzE4NDE1Mjl9.WHJA0PIj_VmPAmJD3neyom5hTpMw_3LR2YkfxW_aGd7g1-6ZwB6rtqVGMKuo7czO6Ox5GnqG6v2xMvMu5Ci2og`,
            },
          }
        );
        // Map the response to provider names for the dropdown
        const mappedBoards = response.data.map((board) => ({
          value: board.opcode, // Use opcode as the value
          label: board.providerName, // Use providerName as the display text
        }));
        setWaterBoards(mappedBoards);
      } catch (error) {
        console.error("Error fetching water boards:", error);
      }
    };

    fetchWaterBoards();
  }, []);

  const handleProceed = () => {
    if (waterBoard && consumerID) {
      setModalOpen(true);
    } else {
      alert("Please fill in all fields.");
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <FormLabel
        component="legend"
        sx={{ fontWeight: "bold", fontSize: "18px", color: "black" }}
      >
        Water Bill Payment
      </FormLabel>

      <Grid container spacing={2} style={{ marginTop: "1%" }}>
        {/* Water Board Selection */}
        <Grid item md={6} xs={12}>
          <TextField
            select
            fullWidth
            label="Water Board"
            value={waterBoard}
            onChange={(e) => setWaterBoard(e.target.value)}
            variant="outlined"
          >
            {waterBoards.map((board) => (
              <MenuItem key={board.value} value={board.value}>
                {board.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* ID Input */}
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Consumer ID"
            value={consumerID}
            onChange={(e) => setConsumerID(e.target.value)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      {/* Proceed Button */}
      <Box
        sx={{
          marginTop: "2%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleProceed}
          sx={{
            color: "white",
            backgroundColor: "#027BFE",
            textTransform: "none",
            fontSize: "16px",
            width: "16%",
          }}
        >
          Proceed
        </Button>
      </Box>

      {/* Payment Modal */}
      <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>{/* Modal content goes here */}</DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewWaterBill;
